import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../common/enum/PopUpConfirmationType";
import {
    deleteRequestListActuation,
    postInspectionActuation,
    postRequestListActuation,
} from "../../../../../../redux/actions/request/edit/action/actuation";
import { deleteInspectionActuation } from "../../../../../../redux/actions/request/edit/action/inspection";
import { postSetMultipleActuations } from "../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
    resetActuationFormDataState,
    setActuationFormLoading,
    setEditRequestActuationPopUp
} from "../../../../../../redux/reducers/request/edit/form/actuation";
import { setPersistScrollOnDetailsList } from "../../../../../../redux/reducers/webConfig/globalConfig";
import { widths } from "../../../../../../styles/romeu/widths";
import { PopUpConfirmation } from "../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
import { ActuationForm } from "../../../../form/edit/generalInformation/inspection/controller/form/actuation/form";
interface IActuationFormControllerProps {
    requestId: number;
    disabledOrganismSelector?: boolean;
    isEditRequest?: boolean;
    showInspectorTime?: boolean;
    delay?: boolean;
    showFormActuationModal: boolean;
    onDismissFormActuationModal: (value: any) => any;
    showDeleteActuationModal: boolean;
    onDismissDeleteActuationModal: (value: any) => any;
    organismIdExpedient?: number;
}

export const ActuationFormController: React.FC<IActuationFormControllerProps> = ({
    requestId,
    disabledOrganismSelector,
    isEditRequest,
    showInspectorTime,
    delay,
    showFormActuationModal,
    onDismissFormActuationModal,
    showDeleteActuationModal,
    onDismissDeleteActuationModal,
    organismIdExpedient
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "LIST.EXPEDIENTS_DESCRIPTIONS" });
    const { form, loadingForm, showPopUp } = useAppSelector((store) => store.actuationForm);
    const { organismId } = useAppSelector((state) => state.userData.profile);
    const { organizations } = useAppSelector((state) => state.userData.profile);
    const { impersonatedOrganismId } = useParams();
    const { data } = useAppSelector((store) => store.catalog.organisms);

    const currentOrganization = useAppSelector((store) => store.organization.organization.id);

    const organization = useMemo(() => {
        return organizations.find((item) => item.id === currentOrganization);
    }, [organizations, currentOrganization]);

    const onSaveFormActuation = () => {
        if (form.isMultiple) {
            dispatch(postSetMultipleActuations(requestId, form, delay, organization?.preappointment && isEditRequest)).then((response: any) => {
                response && onDimissedFormActuation();
            });
        } else if (isEditRequest) {
            dispatch(postInspectionActuation(requestId, form.inspectionId ?? 0, form, organization?.preappointment)).then((response: any) => {
                response && onDimissedFormActuation();
            });
        } else {
            dispatch(postRequestListActuation(requestId, form.inspectionId ?? 0, form, delay)).then((response: any) => {
                response && onDimissedFormActuation();
            });
        }
        dispatch(setPersistScrollOnDetailsList(true));

    };

    const organism = useMemo(() => {

        if(organismId && organismIdExpedient){
            return organismIdExpedient;
        }

        if (!organismId && impersonatedOrganismId && data.length > 0) {
            return data.find((item) => item.code === impersonatedOrganismId)?.id;
        }
        return organismId;
    }, [organismId, impersonatedOrganismId, data, organismIdExpedient]);

    const onCancelFormActuation = () => {
        dispatch(setEditRequestActuationPopUp(PopUpCodes.NONE));
        onDismissFormActuationModal(false);
    };

    const onDimissedFormActuation = () => {
        dispatch(setActuationFormLoading(false));
        dispatch(resetActuationFormDataState()); 
        onCancelFormActuation();
    };

    const onDeleteActuation = () => {
        if (isEditRequest) {
            dispatch(deleteInspectionActuation(requestId, form.inspectionId ?? 0, form.id, organization?.preappointment)).then((response: any) => {
                response && onDimissedDeleteActuation();
            });
        } else {
            dispatch(deleteRequestListActuation(requestId, form.inspectionId ?? 0, form.id ?? 0, true)).then((response: any) => {
                response && onDimissedDeleteActuation();
            });
        }
    };

    const onCancelDeleteActuation = () => {
        dispatch(setEditRequestActuationPopUp(PopUpCodes.NONE));
        onDismissDeleteActuationModal(false);
    };

    const onDimissedDeleteActuation = () => {
        dispatch(setActuationFormLoading(false));
        onCancelDeleteActuation();
        dispatch(resetActuationFormDataState()); 
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            if (form.isMultiple) {
                return t("CREATE_ACTUATION_MULTIPLE");
            }
            return t("NEW_ACTUATION");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("EDIT_ACTUATION");
        }

        return "";
    }, [showPopUp]);

    const isValidform = useMemo(() => {
        return !form.typeId || !form.organismId;
    }, [form]);

    return (
        <>
            <PopUpForm
                content={
                    <ActuationForm
                        selectedOrganism={organism}
                        disabledOrganismSelector={disabledOrganismSelector}
                        showInspectorArrive={showInspectorTime}
                    />
                }
                title={titlePopUp}
                isVisible={showFormActuationModal}
                onCancel={onCancelFormActuation}
                onSave={onSaveFormActuation}
                onDimissed={onDimissedFormActuation}
                height={"35vh"}
                width={"30vw"}
                isBlocking={false}
                isDisabledButton={isValidform}
                leftButtonContent={<RequiredFieldsMessage />}
                isLoading={loadingForm}
            />

            <PopUpConfirmation
                showPopUp={showDeleteActuationModal}
                onDimissed={onDimissedDeleteActuation}
                onCancel={onCancelDeleteActuation}
                onAccept={onDeleteActuation}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
                loading={loadingForm}
            />
        </>
    );
};
