import { initializeIcons, registerIcons } from "@fluentui/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./App.css";
import * as Icon from "./assets/icons";
import ErrorBoundary from "./components/error/errorBoundary";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./services/i18n";
import "./services/telemetryService";

const setupIcons = () => {
    const iconNamesAndComponents = {
        FilterIcon: React.createElement(Icon.FilterIcon),
        CalendarIcon: React.createElement(Icon.CalendarIcon),
        ChevronDownIcon: React.createElement(Icon.ChevronDownIcon),
        ExportIcon: React.createElement(Icon.ExportIcon),
        EditIcon: React.createElement(Icon.EditIcon),
        DetailIcon: React.createElement(Icon.DetailIcon),
        EyeIcon: React.createElement(Icon.EyeIcon),
        FilterColumnIcon: React.createElement(Icon.FilterColumnIcon),
        FilterColumnUpIcon: React.createElement(Icon.FilterColumnUpIcon),
        FilterColumnDownIcon: React.createElement(Icon.FilterColumnDownIcon),
        DropDownUpIcon: React.createElement(Icon.DropDownUpIcon),
        DropDownIcon: React.createElement(Icon.DropDownIcon),
        PortIcon: React.createElement(Icon.PortIcon),
        HistoryIcon: React.createElement(Icon.HistoryIcon),
        PrintIcon: React.createElement(Icon.PrintIcon),
        RemoveIcon: React.createElement(Icon.RemoveIcon),
        ReloadIcon: React.createElement(Icon.ReloadIcon),
        AddIconRed: React.createElement(Icon.AddIconRed),
        AddIconBlue: React.createElement(Icon.AddIconBlue),
        AddIcon: React.createElement(Icon.AddIcon),
        FlagIcon: React.createElement(Icon.FlagIcon),
        LightFlagIcon: React.createElement(Icon.LightFlagIcon),
        DarkFlagIcon: React.createElement(Icon.DarkFlagIcon),
        DownloadIcon: React.createElement(Icon.DownloadIcon),
        ClockIcon: React.createElement(Icon.ClockIcon),
        InfoIcon: React.createElement(Icon.InfoIcon),
        FlagRight: React.createElement(Icon.FlagRight),
        AddActuationMultiple: React.createElement(Icon.AddActuationMultiple),
        FileDownload: React.createElement(Icon.FileDownload),
    };

    const icons = Object.entries(iconNamesAndComponents).reduce<Record<string, JSX.Element>>((acc, [name, element]) => {
        acc[name] = element;
        return acc;
    }, {});

    initializeIcons(
        'https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/',
        { disableWarnings: true } 
      );

    registerIcons({ icons });
};

function renderApp() {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </Provider>
        </React.StrictMode>,
        document.getElementById("root"),
    );
}

setupIcons();
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
