import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../assets/icons";
import { IDockDisplayAssigned } from "../../../../models/management/dockDisplay/IDockDisplay";
import { CustomIcons } from "../../../common/customIcons/customIcons";
import { ManagementDockLedDisplayAssignedVehiclesStyle } from "./assigned.jss";

interface IManagementDockDisplayAssignedVehiclesProps {
    vehicle: IDockDisplayAssigned;
}

export const ManagementDockLedDisplayAssignedVehicles: React.FC<IManagementDockDisplayAssignedVehiclesProps> = ({ vehicle }) => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.DOCK_DISPLAY" });
    const theme = useTheme();
    const style = ManagementDockLedDisplayAssignedVehiclesStyle({ theme, newAssigned: vehicle.new });
    const [showAnimated, setShowAnimated] = useState<boolean>(vehicle.new);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (vehicle.new) {
            timeoutId = setTimeout(() => setShowAnimated(false), 10000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [vehicle.new]);

    return (
        <div className={`${style.containerAssigned} ${vehicle.new && showAnimated ? style.animation : ""}`}>
            <div className={style.cell}>
                <div className={style.text}>{vehicle.dockCode}</div>
            </div>
            <div className={style.cell}>
                <div className={style.text}>{vehicle.transportUnitNumber}</div>
            </div>
        </div>
    );
};
