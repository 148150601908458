import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { setRequestFilterEndDate, setRequestFilterStartDate } from "../../../../../redux/reducers/request/list/filter";
import { routeFeatures } from "../../../../../common/constants/routeFeatures";
import { ViewType } from "../../../../../models";
import { DaySelector } from "../../../../../models/requests/IDaySelector";
import { IView } from "../../../../../models/requests/IUserRoleView";
import { onApplyFilterAction } from "../../../../../redux/actions/request/filter/filterRequest";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getDisabledDaysFilter } from "../../../../../utils";
import { CustomDatePicker } from "../../../../common/customDatePicker/customDatePicker";


export const RequestListDatePicker = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const { startDate, endDate } = useAppSelector((state) => state.requestListFilter);
    const { viewPermission } = useAppSelector((state) => state.userRoleViews);

    const viewRequestPermission = useMemo(() => viewPermission.find((feature) => feature.id === routeFeatures.REQUEST), [viewPermission]);

    const viewConfig = useMemo(() => {
        return viewRequestPermission?.views.find((view) => view.type === ViewType.SEARCH) as IView;
    }, [viewRequestPermission?.views]);

    const disabledDaysDatePicker = viewConfig?.fields.some((item) => item.name === "reducedDate");
    const dateDaysBefore = viewConfig?.fields.find((item) => item.name === "dateDaysBefore")?.value ?? undefined;
    const dateDaysAfter = viewConfig?.fields.find((item) => item.name === "dateDaysAfter")?.value ?? undefined;

    const updateRequestDateProperty = (name: "startDate" | "endDate", value: string) => {
        const actions: { [K in typeof name]: Function } = {
            startDate: setRequestFilterStartDate,
            endDate: setRequestFilterEndDate,
        };

        if (actions[name]) {
            dispatch(actions[name](value));
        }
    };

    return (
        <CustomDatePicker
            onAppliFilters={() => dispatch(onApplyFilterAction())}
            title={t("REQUEST_FILTER.TITLES.REQUEST_DATE")}
            startDate={startDate}
            endDate={endDate}
            maxDaySelected={DaySelector.MAX_DAYS_SELECTED}
            minDaySelected={DaySelector.MIN_DAYS_SELECTED}
            setFilterProperty={updateRequestDateProperty}
            showLeftArrowController={!disabledDaysDatePicker ? true : false}
            dateDaysBefore={dateDaysBefore}
            dateDaysAfter={dateDaysAfter}
        />
    );
};
