import { AppointmentHistoricAPI } from "../../../../api/appointments/historic";
import { throwErrorToastrAsync } from "../../../../utils";
import { setApbaBorderInspectionSummariesList, setApbaBorderInspectionSummariesListLoading } from "../../../reducers/appointment/historic/list/apbaBorderInspectionSummaries";
import { setAssignmentQueueList, setAssignmentQueueListLoading } from "../../../reducers/appointment/historic/list/assignmentQueue";
import { setGlobalLoading } from "../../../reducers/common/globalLoading";
import { AppThunk, store } from "../../../store";

const historicApi = new AppointmentHistoricAPI();

export const fetchAppointmentAPBABorderInspectionsummariesListAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().appointmentHistoricFilter;
        dispatch(setGlobalLoading(true));
        dispatch(setApbaBorderInspectionSummariesListLoading(true));
        const response = await historicApi.getAppointmentAPBABorderInspectionsummariesList(filter);
        dispatch(setApbaBorderInspectionSummariesList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
        dispatch(setApbaBorderInspectionSummariesListLoading(false));
    }
};

export const fetchAppointmentAssignmentQueuesListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGlobalLoading(true));
        const { filter } = store.getState().appointmentHistoricFilter;

        dispatch(setAssignmentQueueListLoading(true));
        const response = await historicApi.getAppointmentAssignmentQueuesList(filter);
        dispatch(setAssignmentQueueList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
        dispatch(setAssignmentQueueListLoading(false));
    }
};
