import { createUseStyles } from "react-jss";

export const ManagementDockDisplayAssignedVehiclesStyle = createUseStyles((theme: any) => ({
    containerAssigned: (props: any) => ({
        display: "flex",
        width: "98%",
        justifyContent: "space-between",
        background: props.newAssigned ? theme.colors.dockDisplay.newAsignedVehicle.background : theme.colors.dockDisplay.asignedVehicle.background,
        border: "5px solid",
        borderColor: props.newAssigned ? theme.colors.dockDisplay.newAsignedVehicle.border : theme.colors.dockDisplay.asignedVehicle.border,
        padding: theme.paddings.sizes.base,
        borderRadius: theme.borders.sizes.md,
        gap: theme.gaps.sizes.base,
    }),
    flag: {
        width: theme.fonts.dockDisplay.icon,
        " & .st0-darck-flag": {
            fill: theme.colors.stateChanger.primary.color,
            stroke: theme.colors.stateChanger.primary.color,
        },
    },
    cell: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "15%",
    },

    doubleCell: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "30%",
    },
    iconCell: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "35%",
    },
    text: {
        fontSize: theme.fonts.dockDisplay.text,
        color: theme.colors.palette.black,
        fontWeight: theme.fonts.dockDisplay.weight,
    },
    textIcon: (props: any) => ({
        fontSize: theme.fonts.dockDisplay.icon,
        color: props.newAssigned ? theme.colors.dockDisplay.newAsignedVehicle.color : theme.colors.stateChanger.primary.color,
       
    }),

    header: {
        fontSize: theme.fonts.dockDisplay.text,
        fontWeight: theme.fonts.weight.bold,
    },

    "@keyframes slideRight": {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    animation: {
        animationName: "$slideRight",
        animationDuration: "1s",
        animation: "linear 1s infinite",
    },
    "@media (orientation: portrait)": {
        textIcon: {
            display: "none",
        },
        iconCell: {
            width: "10%",
            flexDirection: "row",
        },
        doubleCell:{
            width: "55%",
        }
    },
}));
