import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { OrganismTypes, OrganizationTypes, RoleType } from "../../../../../../../common/enum";
import { IRequestFilter } from "../../../../../../../models";
import { IConfigFilterRequest } from "../../../../../../../models/requests/IConfigFilterRequest";
import { fetchOrganisms } from "../../../../../../../redux/actions/catalog/catalog";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { TextInput } from "../../../../../../common/inputs/textInput";
import { BooleaMultiSelector } from "../../../../../../common/selectors/multiSelectors/booleanMultiSelector/booleanMultiSelector";
import { MultipleActuationTypesSelector } from "../../../../../common/selectors/actuationTypes/multipleActuationTypes";
import { MultipleCertificateSelector } from "../../../../../common/selectors/certificate/multipleCertificate";
import { MultipleFinalDestinationSelector } from "../../../../../common/selectors/finalDestination/multipleFinalDestination";
import { MultipleInspectorRoleSelector } from "../../../../../common/selectors/inspector/multipleInspectors";
import { MultipleOrganismSelector } from "../../../../../common/selectors/organism/multipleOrganism";
import { MultipleTerminalSelector } from "../../../../../common/selectors/terminal/multipleTerminal";
import { RequestFilterPivotStyle } from "../filterStyle.jss";
import { use } from "i18next";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    onInputChange: (event: any, name: string) => void;
    onMultipleStringKeysChange: (name: string, options: string[]) => void;
    schema: IConfigFilterRequest;
    onEnterKey: () => void;
}

export const RequestExpedientInfoPivotFilter: React.FC<IProps> = ({
    onMultipleNumberKeysChange,
    onNullableBooleanChange,
    onInputChange,
    onMultipleStringKeysChange,
    schema,
    onEnterKey,
}) => {

    const { id, impersonatedOrganismId } = useParams();

    const theme = useTheme();
    const hasFiveColumns = schema.finalDestinationTypeIdVisible;
    const styles = RequestFilterPivotStyle({ theme, hasFiveColumns });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });
    const { profile } = useAppSelector((state) => state.userData);
    const { data } = useAppSelector((store) => store.catalog.organisms);
    const currentOrganization = useAppSelector((store) => store.organization.organization.id);
    const dispatch = useDispatch();

    const isShowSelector = useMemo(() => {
        const roleTypes = [RoleType.ORGANISM_ADMIN, RoleType.ORGANISM_INSPECTOR, RoleType.ORGANISM_TECHNICIAN];
        const isOrganism = profile.roles.find((item) => roleTypes.includes(item.id as RoleType));

        if ((data && isOrganism) || (profile.organismId && !impersonatedOrganismId)) {
           
            if (currentOrganization === OrganizationTypes.VLC || currentOrganization === OrganizationTypes.SAG) {
                return true;
            }

            let isTaxCanari = data.find((item) => item.code === OrganismTypes.TAX_CANARY);
            let isCustoms = data.find((item) => item.code === OrganismTypes.CUSTOMS);
            if (isTaxCanari?.id === profile.organismId || isCustoms?.id === profile.organismId) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }, [data, id]);


    useEffect(() => {
        data.length === 0 && dispatch(fetchOrganisms());
    }, [dispatch]);

    useEffect(() => {
        if (impersonatedOrganismId  && profile.organismId) {
               onMultipleNumberKeysChange(compileNameOfProperty<IRequestFilter>("organismId"), [profile.organismId]);
        }
    }, [impersonatedOrganismId, profile.organismId]);

    const isDisabledOrganism = useMemo(() => {
        if (currentOrganization === OrganizationTypes.VLC || currentOrganization === OrganizationTypes.SAG) {
            return false;
        }
        return id || impersonatedOrganismId ? true : false
    }, [id, impersonatedOrganismId, currentOrganization]);


    return (
        <div className={`${styles.containerPivot}`}>
            {schema.certificateNumberVisible && (
                <div className={styles.filterItem}>
                    <TextInput
                        value={filter.certificateNumber}
                        propertyName={compileNameOfProperty<IRequestFilter>("certificateNumber")}
                        label={t("CERTIFICATE_NUMBER")}
                        onChange={onInputChange}
                        onEnter={onEnterKey}
                    />
                </div>
            )}
            {schema.certificateTypeIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleCertificateSelector
                        selectedKeys={filter.certificateTypeId}
                        propertyName={compileNameOfProperty<IRequestFilter>("certificateTypeId")}
                        title={t("CERTIFICATE_TYPE")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.actuationTypeIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleActuationTypesSelector
                        selectedKeys={filter.actuationTypeId}
                        propertyName={compileNameOfProperty<IRequestFilter>("actuationTypeId")}
                        title={t("ACTUATION")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.organismIdVisible && isShowSelector && (
                <div className={styles.filterItem}>
                    <MultipleOrganismSelector
                        selectedKeys={filter.organismId}
                        propertyName={compileNameOfProperty<IRequestFilter>("organismId")}
                        title={t("ORGANISM")}
                        onChange={onMultipleNumberKeysChange}
                        disabled={isDisabledOrganism}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.inspectorAssignedVisible && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.inspectorAssigned}
                        propertyName={compileNameOfProperty<IRequestFilter>("inspectorAssigned")}
                        title={t("INSPECTOR_ASSIGNED")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.inspectorIdVisible && (
                <div className={hasFiveColumns ? styles.filterItem2 : styles.filterItem}>
                    <MultipleInspectorRoleSelector
                        organismId={id ? filter.organismId[0] : undefined}
                        selectedKeys={filter.inspectorId}
                        propertyName={compileNameOfProperty<IRequestFilter>("inspectorId")}
                        title={t("INSPECTOR")}
                        onChange={onMultipleStringKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.vuaVisible && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.vua}
                        propertyName={compileNameOfProperty<IRequestFilter>("vua")}
                        title={t("VUA")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.summaryNumberVisible && (
                <div className={styles.filterItem}>
                    <TextInput
                        value={filter.summaryNumber}
                        propertyName={compileNameOfProperty<IRequestFilter>("summaryNumber")}
                        label={t("SUMMARY_NUMBER")}
                        onChange={onInputChange}
                        onEnter={onEnterKey}
                    />
                </div>
            )}
            {schema.terminalIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleTerminalSelector
                        selectedKeys={filter.terminalId}
                        propertyName={compileNameOfProperty<IRequestFilter>("terminalId")}
                        title={t("TERMINAL")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.finalDestinationTypeIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleFinalDestinationSelector
                        selectedKeys={filter.finalDestinationTypeId}
                        propertyName={compileNameOfProperty<IRequestFilter>("finalDestinationTypeId")}
                        title={t("FINALDESTINATION")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
        </div>
    );
};
