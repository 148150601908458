import { ManagementPositioningDisplayApi } from "../../../../api/management/positioningDisplay";
import { Milliseconds } from "../../../../common/enum";
import { Toastr } from "../../../../components/common/toast/toast";

const managementPositioningDisplayApi = new ManagementPositioningDisplayApi();

export const fetchManagementPositioningDisplayAction = async (organization?: string, dockBlock?: string): Promise<any> => {
    try {
        if(dockBlock === "-1") dockBlock = undefined;
        return await managementPositioningDisplayApi.getManagementPositioningDisplayAsync(organization, dockBlock);
    } catch (error) {
        Toastr("", { autoClose: Milliseconds.FIVE_SEC, pauseOnFocusLoss: false }, error).error();
        return false;
    }
};
