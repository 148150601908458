import { IDropdownOption } from "@fluentui/react";
import React, { useEffect } from "react";
import { fetchdockBlocks } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapAllOptionIDescriptionToDropDownOptions, mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors";
import { IBaseSelectorProps } from "../IBaseSelectorProps";
import { IDockBlock } from "../../../../../models/catalog/IDockBlock";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

interface ICustomDockBlockSelectorProps {
    withCode?: boolean;
    allOption?: boolean;
}
export const DockBlockSelector: React.FC<IBaseSelectorProps & ICustomDockBlockSelectorProps> = ({
    onChange,
    propertyName,
    title,
    selectedKey,
    control,
    rules,
    disabled,
    isRequired,
    withCode,
    allOption
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.dockBlock);
    const dispatch = useAppDispatch();

    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _ind?: number) => {
        onChange(option, propertyName);
    };

    useEffect(() => {
        data.length === 0 && dispatch(fetchdockBlocks());
    }, [dispatch, data]);
    
    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={allOption ? mapAllOptionIDescriptionToDropDownOptions(
                data.map((item: IDockBlock) => {
                    return {
                        id: withCode ? item.code : item.id,
                        description: `${item.code} - ${item.description??DefaultValues.EMPTY_LIST}`,
                    };
                }),
            ) : mapIDescriptionToDropDownOptions(
                data.map((item: IDockBlock) => {
                    return {
                        id: withCode ? item.code : item.id,
                        description: `${item.code} - ${item.description??DefaultValues.EMPTY_LIST}`,
                    };
                }),
            )}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
        />
    );
};
