import { useTheme } from "react-jss";
import { IDockDisplayWaiting } from "../../../../models/management/dockDisplay/IDockDisplay";
import { ManagementDockLedDisplayWaitingVehiclesStyle } from "./waitingStyle.jss";

interface IManagenmentDockDisplayWaitingVehiclesProps {
    displayWaiting?: IDockDisplayWaiting[];
}

export const ManagementDockLedDisplayWaitingVehicles: React.FC<IManagenmentDockDisplayWaitingVehiclesProps> = ({ displayWaiting }) => {
    const theme = useTheme();
    const itemCount = displayWaiting?.length || 0;
    const style = ManagementDockLedDisplayWaitingVehiclesStyle({ theme, itemCount });
  
    const getGridRowEndValue = (numberOfItems: number): number => {
        
        const m = 1.20;
        const b = 2
        return Math.ceil(m * numberOfItems + b);
    };

    return (
        <div className={style.containerWaiting}>
            {displayWaiting?.map((item) => (
                <div
                    className={style.gridContainer}
                    key={item.sort}
                    style={{ gridRowEnd: `span ${getGridRowEndValue(item.waitingData.length)}` }}
                >
                    <div className={style.containerTitle}>
                        <div className={style.titleWaiting}>{item.description}</div>
                    </div>
                    <div className={style.itemList}>
                        {item.waitingData.map((data) => {
                            return <div key={data.sort}>{data.transportUnitNumber}</div>;
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};
