import { SkeletonSizes } from "../../../../common/constants";
import { IAppointmentIntegrationsHistoric } from "../../../../models/appointment/IAppointmentIntegrationsHistoric";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ApointmentHistoricListColumn } from "./column/column";

interface IAppointmentIntegrationsHistoricListContainer {
    list?: IAppointmentIntegrationsHistoric[];
    loading: boolean;
    selectedKey: string;
}

export const AppointmentIntegrationsHistoricList: React.FC<IAppointmentIntegrationsHistoricListContainer> = ({ list, loading, selectedKey }) => {
    const renderColumns = () => {
        return ApointmentHistoricListColumn(selectedKey);
    };

    return loading ? (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={renderColumns}
            height="calc(100vh - 20.0rem)"
        />
    );
};
