import es from "date-fns/locale/es";
import React, { useMemo } from "react";
import { DateRange, DayPicker, DayPickerProps } from "react-day-picker";
import { useTheme } from "react-jss";
import { DayPickerStyled } from "./dayPicker.jss";

interface Props {
    onSelect: (date: DateRange | undefined) => void;
    onSelectOnly?: (date: Date | undefined) => void;
    selected: DateRange | undefined;
    selectedOnly?: Date | undefined;
    defaultMonth: Date;
    mode: "range" | "default";
    className?: string;
    minDaySelect: number;
    maxDaySelect: number;
    disabledDays?: {
        from: Date;
        to: Date;
    }[];
}

export const DayPickerComponent: React.FC<Props & DayPickerProps> = ({
    mode,
    selected,
    onSelect,
    defaultMonth,
    minDaySelect,
    maxDaySelect,
    disabledDays,
    toDate,
    fromDate
}) => {
    const theme = useTheme();
    const styles = DayPickerStyled({ theme });

    const css = useMemo(
        () => `
                .rdp {
                    margin: 0; 
                }
                .rdp-month {
                    padding: 15px;
                    margin: none;
                    color: black;
                }
                .rdp-caption_label {
                    text-transform: uppercase;
                }
                .rdp-button:focus, .rdp-button:active {
                    border: none;
                }
                .rdp-button:hover:not([aria-disabled='true']) {
                    color: black;
                }
                `,
        [],
    );

    return (
        <div>
            <style>{css}</style>
            <DayPicker
                locale={es}
                mode={mode}
                defaultMonth={defaultMonth}
                min={minDaySelect}
                max={maxDaySelect}
                selected={selected}
                onSelect={onSelect}
                disabled={disabledDays}
                toDate={toDate}
                fromDate={fromDate}
                modifiersClassNames={{
                    selected: styles.selected,
                    today: styles.today,
                }}
            />
        </div>
    );
};
