import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const ManagementPositioningDisplayContainerStyle = createUseStyles((theme: any) => ({
    header: {
        display: "flex",
        flexDirection: "row",
        textTransform: "uppercase",
        padding: 10,
        fontSize: "calc(5px + 0.6vw)",
        backgroundColor: "#F3F2F7",
        height: 50,
        alignContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        "& div": {
            display: "flex",
            gap: 5,
        },
        [mediaQueries.DISPLAY]: {
            fontSize: "18px !important",
        },
    },
    columnDock: {
        width: "15%",
    },
    gridContent: {
        paddingTop: 115,
    },
    requestDetailRow: {
        display: "flex",
        background: "rgba(245, 245 , 245, 0.5)",
        height: 59,
        width: "100%",
        alignContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        padding: 10,
        height: 70,
        alignContent: "center",
        alignItems: "center",
        borderBottom: "1px dotted black",
        background: "white",
        "& $cell": {
            fontWeight: "bold",
            color: "#4E4E4E",
            fontSize: "calc(5px + 0.65vw)",
            alignContent: "center",
            alignItems: "center",
        },
    },
    cell: {
        display: "flex",
        flexWrap: "nowrap",
        height: 65,
        alignContent: "center",
        alignItems: "center",
    },

    timer: {
        fontSize: theme.fonts.sizes.xxxl,
    },
    physicalExaminationRow: {
        background: "#F8ECE0",
    },
    inspectorReviewRow: {
        background: "#D7F0ED",
    },
    baseLayout: {
        display: "flex",
        height: 49,
        color: "white !important",
        width: "100%",
        margin: 5,
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center",
    },
    physicalExaminationCell: {
        composes: "$baseLayout",
        background: "#FF9F43",
        paddingRight: 5,
        paddingLeft: 5,
        fontSize: "calc(5px + 0.65vw)",
        padding: 5,
        [mediaQueries.DISPLAY]: {
            fontSize: "18px !important",
        },
    },
    physicalExaminationCellNoPaddings: {
        composes: "$baseLayout",
        background: "#FF9F43",
        fontSize: "calc(5px + 0.65vw)",
        [mediaQueries.DISPLAY]: {
            fontSize: "18px !important",
        },
    },
    inspectorReviewCell: {
        composes: "$baseLayout",
        background: "#00C3AC",
        fontSize: "calc(5px + 0.65vw)",
        [mediaQueries.DISPLAY]: {
            fontSize: "18px !important",
        },
    },
    center: {
        justifyContent: "center",
    },
    icon: {
        fontSize: 30,
        color: theme.colors.palette.green,
    },
    infoIcon: {
        fontSize: 30,
        color: theme.colors.palette.purple,
    },
    iconHelper: {
        composes: "$baseLayout",
        width: 100,
    },
    helperContent: {
        display: "flex",
        flexDirection: "column",
        gap: 15,
    },
    helperItem: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        gap: 10,
    },
    helperCell: {
        width: 100,
        height: 50,
    },
    dockBlockSelector: {
        width: "300px",
        padding: "0 15px",
    }
}));
