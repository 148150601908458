import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentIntegrationsHistoric } from "../../../../../models/appointment/IAppointmentIntegrationsHistoric";

interface IApbaBorderInspectionSummariesListState {
    list?: IAppointmentIntegrationsHistoric[];
    loading: boolean;
}

const initialState: IApbaBorderInspectionSummariesListState = {
    loading: false,
};

export const apbaBorderInspectionSummariesList = createSlice({
    name: "apbaBorderInspectionSummariesListReducer",
    initialState,
    reducers: {
        resetApbaBorderInspectionSummariesList: () => initialState,
        setApbaBorderInspectionSummariesList: (state, action: PayloadAction<any[]>) => {
            state.list = action.payload;
        },
        setApbaBorderInspectionSummariesListLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetApbaBorderInspectionSummariesList, setApbaBorderInspectionSummariesList, setApbaBorderInspectionSummariesListLoading } = apbaBorderInspectionSummariesList.actions;

export default apbaBorderInspectionSummariesList.reducer;
