import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IRequestFilter } from "../../../../../../../models";
import { IConfigFilterRequest } from "../../../../../../../models/requests/IConfigFilterRequest";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { NumericInput } from "../../../../../../common/inputs/numericInput";
import { TextInput } from "../../../../../../common/inputs/textInput";
import { BooleaMultiSelector } from "../../../../../../common/selectors/multiSelectors/booleanMultiSelector/booleanMultiSelector";
import { MultipleContainerSizesSelector } from "../../../../../common/selectors/containerSize/multipleContainerSize";
import { MultipleTransportUnitSelector } from "../../../../../common/selectors/transportUnit/multipleTransportUnit";
import { MultipleValuedTypesSelector } from "../../../../../common/selectors/valuedTypes/multipleValuedTypes";
import { RequestFilterPivotStyle } from "../filterStyle.jss";
import { AllCustomerSelector } from "../../../../../../common/selectors/controllerSelectors/customerSelector/allCustomerSelector";
import { MultipleCustomStateSelector } from "../../../../../common/selectors/customStates/multipleCustomState";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    onInputChange: (event: any, name: string) => void;
    onMultipleStringKeysChange: (name: string, options: string[]) => void;
    onInputNumberChange: (value: number | undefined, name: string) => void;
    schema: IConfigFilterRequest;
    onEnterKey: () => void;
}

export const RequestUtiPivotFilter: React.FC<IProps> = ({
    onMultipleNumberKeysChange,
    onNullableBooleanChange,
    onInputChange,
    onMultipleStringKeysChange,
    onInputNumberChange,
    schema,
    onEnterKey,
}) => {
    const theme = useTheme();
    const styles = RequestFilterPivotStyle({ theme });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });

    return (
        <div className={`${styles.containerPivot}`}>
            {schema.sortVisible && (
                <div className={styles.filterItem}>
                    <NumericInput
                        value={filter.sort}
                        propertyName={compileNameOfProperty<IRequestFilter>("sort")}
                        label={t("ORDER")}
                        onChange={onInputNumberChange}
                        min={1}
                        max={1000}
                        onEnter={onEnterKey}
                    />
                </div>
            )}
            {schema.transportUnitNumberVisible && (
                <div className={styles.filterItem}>
                    <TextInput
                        value={filter.transportUnitNumber}
                        propertyName={compileNameOfProperty<IRequestFilter>("transportUnitNumber")}
                        label={t("TRANSPORT_UNIT")}
                        onChange={onInputChange}
                        onEnter={onEnterKey}
                    />
                </div>
            )}
            {schema.transportUnitIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleTransportUnitSelector
                        selectedKeys={filter.transportUnitId}
                        propertyName={compileNameOfProperty<IRequestFilter>("transportUnitId")}
                        title={t("TRANSPORT_UNIT_TYPE")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.transportUnitSizeIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleContainerSizesSelector
                        selectedKeys={filter.transportUnitSizeId}
                        propertyName={compileNameOfProperty<IRequestFilter>("transportUnitSizeId")}
                        title={t("CONTAINER_SIZE")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.customerIdVisible && (
                <div className={styles.filterItem}>
                    <AllCustomerSelector
                        onChange={onMultipleStringKeysChange}
                        selectedKeys={filter.customerId}
                        propertyName={compileNameOfProperty<IRequestFilter>("customerId")}
                        title={t("CUSTOMER")}
                        autocomplete={true}
                    />
                </div>
            )}
            {schema.valuedVisible && (
                <div className={styles.filterItem}>
                    <MultipleValuedTypesSelector
                        selectedKeys={filter.valued}
                        propertyName={compileNameOfProperty<IRequestFilter>("valued")}
                        title={t("VALUED")}
                        onChange={onMultipleStringKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.invoicedVisible && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.invoiced}
                        propertyName={compileNameOfProperty<IRequestFilter>("invoiced")}
                        title={t("INVOICED")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.customsStateIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleCustomStateSelector
                        selectedKeys={filter.customsStateId}
                        propertyName={compileNameOfProperty<IRequestFilter>("customsStateId")}
                        title={t("CUSTOMS_STATE")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
        </div>
    );
};
