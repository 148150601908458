import React from "react";
import { AppointmentIntegrationsHistoricContainer } from "./container";

export class AppointmentIntegrationsHistoricController extends React.Component {
   

    render(): React.ReactNode {
        return <AppointmentIntegrationsHistoricContainer />;
    }
}
