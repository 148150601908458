export enum OrganismTypes {
    FOREIGN_HEALTH = "E",
    PLANT_HEALTH = "V",
    SOIVRE = "S",
    ANIMAL_HEALTH = "A",
    CUSTOMS = "D",
    PHARMACY = "F",
    FISHING="P",
    OTHERS = "O",
    TAX_CANARY = "C",
    BORDER_INSPECTION_BODY = "I",   
}