import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { SkeletonSizes, routeUrls } from "../common/constants";
import { routeFeatures } from "../common/constants/routeFeatures";
import { AppointmentImportContainer } from "../components/appointment/appointmentImport/container";
import { AppointmentBoardContainer } from "../components/appointment/board/container";
import { AppointmentHistoricController } from "../components/appointment/historic/controller";
import { RequestPendingAssignmentContainer } from "../components/assignment/container";
import { CallbackAuth } from "../components/auth/callbackAuth";
import { ComponentWithAuth } from "../components/auth/componentWithAuth";
import { SilentRenewAuth } from "../components/auth/silentRenewAuth";
import { AccessError } from "../components/common/accessError/accessError";
import { Skeleton } from "../components/common/skeleton/skeleton";
import { ControlExpedientsContainer } from "../components/control/expedients/container";
import { CookiePolicyContainer } from "../components/cookie/cookiePolice/cookiePolice";
import { LegalNoticeContainer } from "../components/cookie/legalNotice/container";
import { PrivacyPolicyContainer } from "../components/cookie/privacyPolicy/container";
import { InvoicingNonSynchronizedController } from "../components/invoicing/nonSynchronized/controller";
import { InvoicingSummaryByConceptController } from "../components/invoicing/summaryByConcept/controller";
import { InvoicingUninvoicedController } from "../components/invoicing/uninvoiced/controller";
import { CustomTemplate } from "../components/layouts/customTemplate/customTemplate";
import { Template } from "../components/layouts/template/template";
import { TemplateWithoutAuth } from "../components/layouts/templateWithoutAuth/templateWithoutAuth";
import { Login } from "../components/login/login";
import { ManagementAuthorizedTransportUnitNumberContainer } from "../components/management/authorizedTransportUnitNumber/container";
import { ManagementDockDisplayContainer } from "../components/management/dockDisplay/container";
import { ManagementIncidenceTypesContainer } from "../components/management/incidenceTypes/container";
import { ManagementMenuSettingsByRolController } from "../components/management/menuSettingsByRol/controller";
import { ManagementNotificationContainer } from "../components/management/notification/container";
import { ManagementParkingValidationRuleContainer } from "../components/management/parkingValidation/container";
import { ManagementPositioningDisplayContainer } from "../components/management/positioningDisplay/container";
import { ManagementProcessesContainer } from "../components/management/processes/container";
import { ManagementRepresentationContainer } from "../components/management/representation/container";
import { ManagementRequestDatePermissionContainer } from "../components/management/requestdatepermission/container";
import { ManagementActiveSummaryContainer } from "../components/management/summary/container";
import { ManagementTariffContainer } from "../components/management/tariff/container";
import { ManagementUserController } from "../components/management/user/controller";
import { ManagementUserSignupContainer } from "../components/management/userSignup/container";
import { ManagementUserSignupForm } from "../components/management/userSignup/form/form";
import { ManagementWorkCalendarContainer } from "../components/management/workCalendar/container";
import { QueuAssignmentImportContainer } from "../components/queueAssignment/import/container";
import { RequestDraftList } from "../components/request/draft/list/list";
import {
    EditRequestBackToFinishedRequestController,
    EditRequestBackToOrganismController,
    EditRequestController,
} from "../components/request/form/edit/controller";
import { RequestListContainer } from "../components/request/list/controller";
import { CarrierImportContainer } from "../components/resources/carriersImport/container";
import { CustomersImportContainer } from "../components/resources/customersImport/container";
import { ConfigDockBlockContainer } from "../components/resources/dockBlock/container";
import { ConfigDockMerchandiseContainer } from "../components/resources/dockMerchandise/container";
import { MastersContainer } from "../components/resources/master/container";
import { MasterAppointmentContainer } from "../components/resources/masterAppointment/container";
import { AppointmentBoardEditController } from "../components/resources/masterAppointment/list/appointmentBoard/controller/edit/controller";
import { ConfigMerchandiseCategoryContainer } from "../components/resources/merchandiseCategory/container";
import { ConfigOrganismWithCertificateController } from "../components/resources/organismCertificatedType/controller";
import { OrganizationContainer } from "../components/resources/organization/container";
import { StatisticsCustomersContainer } from "../components/statistics/customers/container";
import { StatisticsDailyChargeContainer } from "../components/statistics/dailyCharge/container";
import { StatisticsInspectionGroupedByMonthContainer } from "../components/statistics/inspections/accumulated/list";
import { StatisticsInspectionsContainer } from "../components/statistics/inspections/container";
import { StatisticsMonthlySummaryContainer } from "../components/statistics/monthlySummary/container";
import { StatisticsIncidenceOperatorContainer } from "../components/statistics/operatorlIncidence/container";
import { StatisticsIncidenceOrganismContainer } from "../components/statistics/organismIncidence/container";
import { StatisticsReaderRecordRatioController } from "../components/statistics/readerRecordRatio/controller";
import { StatisticsRequestGraphContainer } from "../components/statistics/requestGraph/container";
import { SuggestionsMailboxContainer } from "../components/suggestionsMailbox/container";
import { UpcomingAppointmentSortController } from "../components/upcomingAppointment/upcominAppointmentsSort/controller";
import { UpcomingAppointmentsContainer } from "../components/upcomingAppointment/upcomingAppointments/container";
import { UserConfirmation } from "../components/user/confirmation/confirmation";
import { UserProfileController } from "../components/user/profile/controller";
import { UserSelfSignUpcontainer } from "../components/user/selfSignUp/container";
import { UserSignupContainer } from "../components/user/signup/container";
import { VlcPortFinishedRequestContainerController } from "../components/vlcPort/finishedRequests/controller";
import { VlcportHistoricContainer } from "../components/vlcPort/historic/container";
import { VlcportImportRequestContainer } from "../components/vlcPort/importRequest/container";
import { IUserModuleData } from "../models/user/IUserModuleData";
import { resetUserAuthAction } from "../redux/actions/userData/userData";
import { useAppDispatch } from "../redux/hooks";
import { getUserFeatures, loadingUserModules } from "../redux/selectors/user/module/module";
import { IntegrationRequestContainer } from "../components/integrationsRequest/container";
import { use } from "i18next";
import { features } from "process";
import { ManagementDockQueueRuleContainer } from "../components/management/dockQueueRule/container";
import { AppointmentIntegrationsHistoricController } from "../components/appointment/integrationsHistoric/controller";
import { ManagementDockLedDisplayContainer } from "../components/management/dockLedDisplay/container";

interface IRouteItem {
    path: string;
    element: any;
    showRoute?: boolean;
    featureId?: any;
}

export const DefaultRouter = (userManager: any) => {
    const { t } = useTranslation("common", { keyPrefix: "ROUTES" });

    const accesError = useTranslation("messages", { keyPrefix: "ERRORS" });
    const apiErrorMessages = useTranslation("common", { keyPrefix: "API_ERRORS_MESSAGE" });

    const userFeatures: IUserModuleData[] = useSelector(getUserFeatures);
    const loading: boolean = useSelector(loadingUserModules);
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(resetUserAuthAction());
        userManager?.signoutRedirect();
    };

    const goToHome = () => {
        return (window.location.href = routeUrls.HOME);
    };
    const routes: IRouteItem[] = [
        {
            path: routeUrls.HOME,
            element: <TemplateWithoutAuth component={() => <Login userManager={userManager} />} />,
            showRoute: true,
        },
        {
            path: routeUrls.FORBIDDEN,
            element: (
                <TemplateWithoutAuth
                    component={() => (
                        <AccessError
                            iconName={"Lock"}
                            title={accesError.t("FORBIDDEN_TITLE")}
                            message={accesError.t("FORBIDDEN_MESSAGE")}
                            handleClickButton={handleClick}
                            buttonTitle={accesError.t("FORBIDDEN_BUTTON")}
                        />
                    )}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.INTERNAL_SERVER_ERROR,
            element: (
                <TemplateWithoutAuth
                    component={() => (
                        <AccessError
                            iconName={"PlugDisconnected"}
                            title={apiErrorMessages.t("DEFAULT")}
                            message={apiErrorMessages.t("DEFAULT_MESSAGE")}
                            buttonTitle={accesError.t("DEFAULT_BUTTON")}
                            handleClickButton={goToHome}
                        />
                    )}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.SIGNUP,
            element: <TemplateWithoutAuth component={() => <UserSignupContainer />} />,
            showRoute: true,
        },
        {
            path: routeUrls.SIGNIN_CALLBACK,
            element: <CallbackAuth userManager={userManager} />,
            showRoute: true,
        },
        {
            path: routeUrls.SILENT_RENEW,
            element: <SilentRenewAuth userManager={userManager} />,
            showRoute: true,
        },
        {
            path: routeUrls.SIGNUP_CONFIRMATION,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <UserConfirmation />}
                    title={t("SIGNUP_CONFIRMATION")}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.USER_REGISTRATION,
            element: <TemplateWithoutAuth component={() => <UserSelfSignUpcontainer />} />,
            showRoute: true,
        },
        {
            path: routeUrls.PROFILE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <UserProfileController />}
                    title={t("MANAGEMENT")}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.REPRESENTATION,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementRepresentationContainer />}
                    title={t("MANAGEMENT")}
                />
            ),

            featureId: routeFeatures.MANAGEMENT_REPRESENTATIVE,
        },
        {
            path: routeUrls.MANAGEMENT_NOTIFICATIONS,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementNotificationContainer />}
                    title={t("MANAGEMENT")}
                />
            ),
            featureId: routeFeatures.MANAGEMENT_NOTIFICATION,
        },
        {
            path: routeUrls.REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    featureId={routeFeatures.REQUEST}
                />
            ),
            featureId: routeFeatures.REQUEST,
        },
        {
            path: routeUrls.ORGANISM_BORDER_INSPECTION_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_BORDER,
        },
        {
            path: routeUrls.ORGANISM_BORDER_INSPECTION_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_BORDER,
        },
        {
            path: routeUrls.ORGANISM_VEGETABLE_HEALTH_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.ORGANISM_REQUEST_ANIMAL_HEALTH,
        },
        {
            path: routeUrls.ORGANISM_VEGETABLE_HEALTH_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.ORGANISM_REQUEST_ANIMAL_HEALTH,
        },

        {
            path: routeUrls.ORGANISM_VEGETABLE_HEALTH_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_VEGETABLE_HEALTH,
        },
        {
            path: routeUrls.ORGANISM_FOREIGN_HEALTH_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_VEGETABLE_HEALTH,
        },

        {
            path: routeUrls.ORGANISM_ANIMAL_HEALTH_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_ANIMAL_HEALTH,
        },
        {
            path: routeUrls.ORGANISM_ANIMAL_HEALTH_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_ANIMAL_HEALTH,
        },
        {
            path: routeUrls.ORGANISM_FOREIGN_HEALTH_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_ANIMAL_HEALTH,
        },
        {
            path: routeUrls.ORGANISM_FOREIGN_HEALTH_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_FOREIGNH_HEALTH,
        },

        {
            path: routeUrls.ORGANISM_PHARMACY_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_PHARMACY,
        },
        {
            path: routeUrls.ORGANISM_PHARMACY_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_PHARMACY,
        },

        {
            path: routeUrls.ORGANISM_SOIVRE_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_SOIVRE,
        },
        {
            path: routeUrls.ORGANISM_SOIVRE_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_SOIVRE,
        },        
        {
            path: routeUrls.ORGANISM_CUSTOMS_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_CUSTOMS,
        },
        {
            path: routeUrls.ORGANISM_CUSTOMS_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_CUSTOMS,
        },
        {
            path: routeUrls.ORGANISM_FISHING_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_FISHING,
        },
        {
            path: routeUrls.ORGANISM_FISHING_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_FISHING,
        },

        {
            path: routeUrls.ORGANISM_CANARIAN_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_CANARIAN,
        },
        {
            path: routeUrls.ORGANISM_CANARIAN_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_CANARIAN,
        },
        {
            path: routeUrls.ORGANISM_OTHERS_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestListContainer />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_OTHERS,
        },
        {
            path: routeUrls.ORGANISM_OTHERS_EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToOrganismController />}
                    title={t("REQUEST")}
                />
            ),
            featureId: routeFeatures.REQUEST_OTHERS,
        },

        {
            path: routeUrls.EDIT_FINISHED_REQUESTS,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestBackToFinishedRequestController />}
                    featureId={routeFeatures.REQUEST}
                />
            ),
            featureId: routeFeatures.REQUEST,
        },
        {
            path: routeUrls.EDIT_REQUEST,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <EditRequestController />}
                    featureId={routeFeatures.REQUEST}
                />
            ),
            featureId: routeFeatures.REQUEST,
        },
        {
            path: routeUrls.CALENDAR,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsInspectionGroupedByMonthContainer />}
                    featureId={routeFeatures.CALENDAR}
                />
            ),
            featureId: routeFeatures.CALENDAR,
        },
        {
            path: routeUrls.DRAFT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestDraftList />}
                    featureId={routeFeatures.DRAFT}
                />
            ),
            featureId: routeFeatures.DRAFT,
        },
        {
            path: routeUrls.INVOICING_SUMARY_BY_CONCEPT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <InvoicingSummaryByConceptController />}
                    title={t("INVOICE")}
                />
            ),
            featureId: routeFeatures.INVOICING_SUMARY_BY_CONCEPT,
        },
        {
            path: routeUrls.INVOICING_UNINVOICED,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <InvoicingUninvoicedController />}
                    title={t("INVOICE")}
                />
            ),
            featureId: routeFeatures.INVOICING_UNINVOICED,
        },
        {
            path: routeUrls.INVOICING_NON_SYNCHRONIZED,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <InvoicingNonSynchronizedController />}
                    title={t("INVOICE")}
                />
            ),
            featureId: routeFeatures.INVOICING_UNINVOICED,
        },
        {
            path: routeUrls.MANAGEMENT_USER,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementUserController />}
                    title={t("MANAGEMENT")}
                />
            ),
            featureId: routeFeatures.MANAGEMENT_USER,
        },
        {
            path: routeUrls.MANAGEMENT_USER_SIGNUP,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementUserSignupContainer />}
                    title={t("MANAGEMENT")}
                />
            ),
            featureId: routeFeatures.MANAGEMET_USER_SIGNUP,
        },
        {
            path: routeUrls.MANAGEMENT_USER_SIGNUP_FORM,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementUserSignupForm />}
                    title={t("MANAGEMENT")}
                />
            ),
            featureId: routeFeatures.MANAGEMET_USER_SIGNUP,
        },
        {
            path: routeUrls.MANAGEMENT_TARIFF,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementTariffContainer />}
                    featureId={routeFeatures.STATISTICS_CUSTOMER}
                    title={t("MANAGEMENT")}
                />
            ),
            featureId: routeFeatures.MANAGEMENT_TARIFF,
        },
        {
            path: routeUrls.MANAGEMENT_WORK_CALENDAR,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementWorkCalendarContainer />}
                    featureId={routeFeatures.MANAGEMENT_WORK_CALENDAR}
                    title={t("MANAGEMENT")}
                />
            ),
            featureId: routeFeatures.MANAGEMENT_WORK_CALENDAR,
        },
        {
            path: routeUrls.STATISTICS_CUSTOMER,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsCustomersContainer />}
                    title={t("STATISTICS")}
                    featureId={routeFeatures.STATISTICS_CUSTOMER}
                />
            ),

            featureId: routeFeatures.STATISTICS_CUSTOMER,
        },
        {
            path: routeUrls.STATISTICS_ORGANISM_INCIDENCE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsIncidenceOrganismContainer />}
                    title={t("STATISTICS")}
                    featureId={routeFeatures.STATISTICS_INCIDENCE_ORGANISM}
                />
            ),
            featureId: routeFeatures.STATISTICS_INCIDENCE_ORGANISM,
        },
        {
            path: routeUrls.STATISTICS_INSPECTION,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsInspectionsContainer />}
                    title={t("STATISTICS")}
                    featureId={routeFeatures.STATISTICS_INSPECTION}
                />
            ),
            featureId: routeFeatures.STATISTICS_INSPECTION,
        },
        {
            path: routeUrls.STATISTICS_ORGANISM_INCIDENCE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsIncidenceOrganismContainer />}
                    title={t("STATISTICS")}
                    featureId={routeFeatures.STATISTICS_INCIDENCE_ORGANISM}
                />
            ),
            featureId: routeFeatures.STATISTICS_INCIDENCE_ORGANISM,
        },
        {
            path: routeUrls.STATISTICS_OPERATOR_INCIDENCE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsIncidenceOperatorContainer />}
                    title={t("STATISTICS")}
                    featureId={routeFeatures.STATISTICS_INCIDENCE_OPERATOR}
                />
            ),
            featureId: routeFeatures.STATISTICS_INCIDENCE_OPERATOR,
        },
        {
            path: routeUrls.STATISTICS_MONTHLY_SUMMARY,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsMonthlySummaryContainer />}
                    title={t("STATISTICS")}
                    featureId={routeFeatures.STATISTICS_INCIDENCE_OPERATOR}
                />
            ),
            featureId: routeFeatures.STATISTICS_INCIDENCE_OPERATOR,
        },
        {
            path: routeUrls.STATISTICS_DAILY_CHARGE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsDailyChargeContainer />}
                    title={t("STATISTICS")}
                    featureId={routeFeatures.STATISTICS_DAILY_CHARGE}
                />
            ),
            featureId: routeFeatures.STATISTICS_DAILY_CHARGE,
        },
        {
            path: routeUrls.DOCK_DISPLAY,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={CustomTemplate}
                    component={() => <ManagementDockDisplayContainer />}
                    featureId={routeFeatures.DOCK_DISPLAY}
                />
            ),
            featureId: routeFeatures.DOCK_DISPLAY,
        },
        {
            path: routeUrls.DOCK_LED_DISPLAY,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={CustomTemplate}
                    component={() => <ManagementDockLedDisplayContainer />}
                    featureId={routeFeatures.DOCK_DISPLAY}
                />
            ),
            featureId: routeFeatures.DOCK_DISPLAY,
        },
        {
            path: routeUrls.POSITIONING_DISPLAY,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={CustomTemplate}
                    component={() => <ManagementPositioningDisplayContainer />}
                    featureId={routeFeatures.POSITIONING_DISPLAY}
                />
            ),
            featureId: routeFeatures.POSITIONING_DISPLAY,
        },
     
        {
            path: routeUrls.STATISTICS_READER_RECORD_RATIO,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsReaderRecordRatioController />}
                    title={t("STATISTICS")}
                    featureId={routeFeatures.STATISTICS_READER_RECORD_RATIO}
                />
            ),
            featureId: routeFeatures.STATISTICS_READER_RECORD_RATIO,
        },
        {
            path: routeUrls.STATISTICS_REQUEST_GRAPH,
            element: (
                <ComponentWithAuth
                    title={t("STATISTICS")}
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <StatisticsRequestGraphContainer />}
                    featureId={routeFeatures.STATISTICS_REQUEST_GRAPH}
                />
            ),
            featureId: routeFeatures.STATISTICS_REQUEST_GRAPH,
        },
        {
            path: routeUrls.STATISTICS_REQUEST_GRAPH_ORGANIZATION,
            element: (
                <TemplateWithoutAuth
                    clearTemplate={true}
                    component={() => <StatisticsRequestGraphContainer />}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.MANAGEMENT_INCIDENCE_TYPE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementIncidenceTypesContainer />}
                    title={t("MANAGEMENT")}
                />
            ),
            featureId: routeFeatures.MANAGEMENT_INCIDENCE_TYPE,
        },
        {
            path: routeUrls.MANAGEMENT_REQUEST_DATEPERMISION,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementRequestDatePermissionContainer />}
                    title={t("MANAGEMENT")}
                    featureId={routeFeatures.MANAGEMENT_REQUEST_DATEPERMISION}
                />
            ),
            featureId: routeFeatures.MANAGEMENT_REQUEST_DATEPERMISION,
        },
        {
            path: routeUrls.MASTER,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <MastersContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.MASTERS}
                />
            ),
            featureId: routeFeatures.MASTERS,
        },
        {
            path: routeUrls.ORGANIZATION,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <OrganizationContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.ORGANIZATION}
                />
            ),
            featureId: routeFeatures.ORGANIZATION,
        },
        {
            path: routeUrls.CONTROL_EXPEDIENT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ControlExpedientsContainer />}
                    title={t("MASTER")}
                    featureId={routeFeatures.CONTROL_EXPEDIENT}
                />
            ),
            featureId: routeFeatures.CONTROL_EXPEDIENT,
        },
        {
            path: routeUrls.ASSIGNMENT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <RequestPendingAssignmentContainer />}
                    title={t("REQUEST")}
                    featureId={routeFeatures.ASSIGNMENT}
                />
            ),
            featureId: routeFeatures.ASSIGNMENT,
        },
        {
            path: routeUrls.MANAGEMENT_SUMMARY,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementActiveSummaryContainer />}
                    title={t("MANAGEMENT")}
                    featureId={routeFeatures.MANAGEMENT_SUMMARY}
                />
            ),
            featureId: routeFeatures.MANAGEMENT_SUMMARY,
        },
        {
            path: routeUrls.UPCOMING_APPOINTMENTS,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <UpcomingAppointmentsContainer />}
                    title={t("REQUEST")}
                    featureId={routeFeatures.UPCOMING_APPOINTMENTS}
                />
            ),
            featureId: routeFeatures.UPCOMING_APPOINTMENTS,
        },
        {
            path: routeUrls.UPCOMING_APPOINTMENTS_SORT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <UpcomingAppointmentSortController />}
                    title={t("REQUEST")}
                    featureId={routeFeatures.UPCOMING_APPOINTMENTS}
                />
            ),
            featureId: routeFeatures.UPCOMING_APPOINTMENTS_SORT,
        },

        {
            path: routeUrls.CONFIG_MERCHANDISE_CATEGORY,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ConfigMerchandiseCategoryContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.CONFIG_MERCHANDISE_CATEGORY}
                />
            ),
            featureId: routeFeatures.CONFIG_MERCHANDISE_CATEGORY,
        },
        {
            path: routeUrls.CONFIG_MERCHANDISE_CATEGORY,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ConfigMerchandiseCategoryContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.CONFIG_MERCHANDISE_CATEGORY}
                />
            ),
            featureId: routeFeatures.CONFIG_MERCHANDISE_CATEGORY,
        },
        {
            path: routeUrls.CONFIG_DOCK_MERCHANDISE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ConfigDockMerchandiseContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.CONFIG_DOCK_MERCHANDISE}
                />
            ),
            featureId: routeFeatures.CONFIG_DOCK_MERCHANDISE,
        },
        {
            path: routeUrls.APPOINTMENT_BOARD,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <AppointmentBoardContainer />}
                    title={t("APPOINTMENT")}
                    featureId={routeFeatures.APPOINTMENT_DASHBOARD}
                />
            ),
            featureId: routeFeatures.APPOINTMENT_DASHBOARD,
        },
        {
            path: routeUrls.APPOINTMENT_IMPORT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <AppointmentImportContainer />}
                    title={t("APPOINTMENT")}
                    featureId={routeFeatures.APPOINTMENT_IMPORT}
                />
            ),
            featureId: routeFeatures.APPOINTMENT_DASHBOARD,
        },
        {
            path: routeUrls.QUEUEASSIGNMENT_IMPORT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <QueuAssignmentImportContainer />}
                    title={t("QUEUEASSIGNMENT")}
                    featureId={routeFeatures.QUEUEASSIGNMENT_IMPORT}
                />
            ),
            featureId: routeFeatures.APPOINTMENT_DASHBOARD,
        },

        {
            path: routeUrls.CONFIG_DOCK_BLOCK,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ConfigDockBlockContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.CONFIG_DOCK_BLOCK}
                />
            ),
            featureId: routeFeatures.CONFIG_DOCK_BLOCK,
        },

        {
            path: routeUrls.MASTER_APPOINTMENT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <MasterAppointmentContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.MASTER_APPOINTMENT}
                />
            ),
            featureId: routeFeatures.MASTER_APPOINTMENT,
        },

        {
            path: routeUrls.MASTER_APPOINTMENT_BOARD,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <AppointmentBoardEditController />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.MASTER_APPOINTMENT}
                />
            ),
            featureId: routeFeatures.MASTER_APPOINTMENT,
        },
        {
            path: routeUrls.USER_IMPORT,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <CustomersImportContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.USER_IMPORT}
                />
            ),
            featureId: routeFeatures.USER_IMPORT,
        },
        {
            path: routeUrls.AUTHORIZED_TRANSPORT_UNIT_NUMBER,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementAuthorizedTransportUnitNumberContainer />}
                    title={t("MANAGEMENT")}
                    featureId={routeFeatures.AUTHORIZED_TRANSPORT_UNIT_NUMBER}
                />
            ),
            featureId: routeFeatures.AUTHORIZED_TRANSPORT_UNIT_NUMBER,
        },
        {
            path: routeUrls.SUGGESTIONS_MAILBOX,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    title={t("CONTACT")}
                    component={() => <SuggestionsMailboxContainer />}
                    featureId={routeFeatures.SUGGESTIONS_MAILBOX}
                />
            ),
            showRoute: true,
        },

        {
            path: routeUrls.FINISHED_REQUESTS,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <VlcPortFinishedRequestContainerController />}
                    title={t("VALENCIA_PORT")}
                />
            ),
            featureId: routeFeatures.VLCPORT_INSPECTION_RESULT,
        },
        {
            path: routeUrls.IMPORT_REQUESTS,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <VlcportImportRequestContainer />}
                    title={t("VALENCIA_PORT")}
                />
            ),
            featureId: routeFeatures.VLCPORT_POSITIONING_IMPORT,
        },
        {
            path: routeUrls.VLCPORT_HISTORIC,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <VlcportHistoricContainer />}
                    title={t("VALENCIA_PORT")}
                    featureId={routeFeatures.VLCPORT_HISTORIC}
                />
            ),
            featureId: routeFeatures.VLCPORT_HISTORIC,
        },
        {
            path: routeUrls.LEGAL_NOTICE,
            element: <TemplateWithoutAuth component={() => <LegalNoticeContainer />} />,
            showRoute: true,
        },
        {
            path: routeUrls.PRIVACY_POLICY,
            element: <TemplateWithoutAuth component={() => <PrivacyPolicyContainer />} />,
            showRoute: true,
        },
        {
            path: routeUrls.COOKIES_POLICE,
            element: <TemplateWithoutAuth component={() => <CookiePolicyContainer />} />,
            showRoute: true,
        },

        {
            path: routeUrls.DOCK_DISPLAY_ORGANIZATION,
            element: (
                <TemplateWithoutAuth
                    clearTemplate={true}
                    component={() => <ManagementDockDisplayContainer />}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.DOCK_LED_DISPLAY_ORGANIZATION,
            element: (
                <TemplateWithoutAuth
                    clearTemplate={true}
                    component={() => <ManagementDockLedDisplayContainer />}
                />
            ),
            showRoute: true,
        },

        {
            path: routeUrls.POSITIONING_DISPLAY_ORGANIZATION,
            element: (
                <TemplateWithoutAuth
                    clearTemplate={true}
                    component={() => <ManagementPositioningDisplayContainer />}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.POSITIONING_DISPLAY_ORGANIZATION_DOCKBLOCK,
            element: (
                <TemplateWithoutAuth
                    clearTemplate={true}
                    component={() => <ManagementPositioningDisplayContainer />}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.NOT_FOUND,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    title={accesError.t("NOT_FOUND_TITLE")}
                    component={() => (
                        <AccessError
                            iconName={"Info"}
                            title={accesError.t("NOT_FOUND_TITLE")}
                            message={accesError.t("NOT_FOUND_MESSAGE")}
                            handleClickButton={goToHome}
                            buttonTitle={accesError.t("DEFAULT_BUTTON")}
                        />
                    )}
                />
            ),
            showRoute: true,
        },
        {
            path: routeUrls.PARKING_VALIDATION_RULE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementParkingValidationRuleContainer />}
                    title={t("MANAGEMENT")}
                    featureId={routeFeatures.PARKING_VALIDATION_RULE}
                />
            ),
            featureId: routeFeatures.PARKING_VALIDATION_RULE,
        },
        {
            path: routeUrls.DOCK_QUEUE_RULE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementDockQueueRuleContainer />}
                    title={t("MANAGEMENT")}
                    featureId={routeFeatures.DOCK_QUEUE_RULE}
                />
            ),
            featureId: routeFeatures.DOCK_QUEUE_RULE,
        },
        {
            path: routeUrls.MENU_SETTINGS,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementMenuSettingsByRolController />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.MENU_SETTINGS}
                />
            ),
            featureId: routeFeatures.MENU_SETTINGS,
        },
        {
            path: routeUrls.ORGANISM_CERTIFICATED_TYPE,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ConfigOrganismWithCertificateController />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.ORGANISM_CERTIFICATED_TYPE}
                />
            ),
            featureId: routeFeatures.ORGANISM_CERTIFICATED_TYPE,
        },
        {
            path: routeUrls.IMPORT_CARRIER,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <CarrierImportContainer />}
                    title={t("RESOURCES")}
                    featureId={routeFeatures.IMPORT_CARRIER}
                />
            ),
            featureId: routeFeatures.IMPORT_CARRIER,
        },
        {
            path: routeUrls.APPOINTMENT_HISTORIC,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <AppointmentHistoricController />}
                    title={t("APPOINTMENT")}
                    featureId={routeFeatures.APPOINTMENT_HISTORIC}
                />
            ),
            featureId: routeFeatures.APPOINTMENT_HISTORIC,
        },
        {
            path: routeUrls.INTEGRATIONS_HISTORIC,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <AppointmentIntegrationsHistoricController />}
                    title={t("APPOINTMENT")}
                    featureId={routeFeatures.INTEGRATIONS_HISTORIC}
                />
            ),
            featureId: routeFeatures.INTEGRATIONS_HISTORIC,
        },
        {
            path: routeUrls.PROCESSES,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <ManagementProcessesContainer />}
                    title={t("PROCESSES")}
                    featureId={routeFeatures.DEVELOPERTOOLS_PROCESSES}
                />
            ),
            featureId: routeFeatures.DEVELOPERTOOLS_PROCESSES,
        },
        {
            path: routeUrls.REQUEST_INTEGRATIONS,
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <IntegrationRequestContainer />}
                    title={t("REQUEST")}
                    featureId={routeFeatures.REQUEST_INTEGRATIONS}
                />
            ),
            featureId: routeFeatures.REQUEST_INTEGRATIONS,
        },
        {
            path: "*",
            element: (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    title={accesError.t("NOT_FOUND_TITLE")}
                    component={() => (
                        <AccessError
                            iconName={"Info"}
                            title={accesError.t("NOT_FOUND_TITLE")}
                            message={accesError.t("NOT_FOUND_MESSAGE")}
                            handleClickButton={goToHome}
                            buttonTitle={accesError.t("DEFAULT_BUTTON")}
                        />
                    )}
                />
            ),
            showRoute: true,
        },
    ];
    
    const checkPermission = (userFeatures: any, featureId: string) => {
        return userFeatures.some((item: any) => item.id === featureId);
    };
    

    
    return routes.map((route, index) => {
        const permission = checkPermission(userFeatures, route.featureId);
    
        const element = permission || route.showRoute || loading ? (
            loading ? (
                <ComponentWithAuth
                    userManager={userManager}
                    componentAuth={Template}
                    component={() => <Skeleton rows={SkeletonSizes.DEFAULT} />}
                />
            ) : (
                route.element
            )
        ) : (
            <ComponentWithAuth
                userManager={userManager}
                componentAuth={Template}
                title={t("NOT_ACCES")}
                component={() => (
                    <AccessError
                        iconName={"Lock"}
                        title={accesError.t("FORBIDDEN_TITLE")}
                        message={accesError.t("FORBIDDEN_MESSAGE")}
                        handleClickButton={handleClick}
                        buttonTitle={accesError.t("FORBIDDEN_BUTTON")}
                    />
                )}
            />
        );
    
        return <Route key={index} path={route.path} element={element} />;
    });
}
