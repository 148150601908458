import { Method } from "../../../common/enum";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/positioningdisplayscreen`;

export class ManagementPositioningDisplayApi {
    async getManagementPositioningDisplayAsync(organization?: string, dockBlock?:string): Promise<any> {
        let url = organization ? `${baseURL}/${organization}` : baseURL;
        
        if(dockBlock !== undefined){
            url = `${url}?dockBlock=${dockBlock}`;
         }

        return organization
            ? apiFetchOAuthWithClaims(Method.GET, url).withHeader("x-organization-identifier", organization).execute<any>()
            : apiFetchOAuthWithClaims(Method.GET, url).execute<any>();
    }
}
