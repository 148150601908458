import { useBoolean, useId } from "@fluentui/react-hooks";
import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useParams } from "react-router";
import { Milliseconds } from "../../../common/enum";
import { FormatDate } from "../../../common/enum/dateTime/FormatDate";
import { IManagmentPositioningDisplay, IManagmentPositioningDocks } from "../../../models/management/positioningDisplay/IManagmentPositioningDisplay";
import { fetchManagementPositioningDisplayAction } from "../../../redux/actions/management/positioningDisplay/positioningDisplay";
import { convertToLocal } from "../../../utils";
import { CustomIcons } from "../../common/customIcons/customIcons";
import { DigitalTimer } from "../../common/digitalTimer/digitalTimer";
import { EnvironmentDescription } from "../../common/environment/environment";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { TeachingBubbleComponent } from "../../common/teachingBubble/teachingBubble";
import { ManagementPositioningDisplayContainerStyle } from "./containerStyle.jss";
import { DockBlockSelector } from "../../request/common/selectors/dockBlock/dockBlockSelector";
import { useForm } from "react-hook-form";

export const ManagementPositioningDisplayContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.POSITIONING_DISPLAY" });
    const { organization, dockBlockId } = useParams();
    const theme = useTheme();
    const style = ManagementPositioningDisplayContainerStyle({ theme });
    const [positioningDisplay, setPositioningDisplay] = useState<IManagmentPositioningDisplay | null>(null);
    const [dockBlock, setDockBlock] = useState<string>(dockBlockId ?? "-1");
    const [helperVisible, { toggle: toggleHelperVisible }] = useBoolean(false);
    const helperId = useId("targetHelper");
    const { control } = useForm<any>({ mode: "onChange" });

    const headerStyle: CSSProperties = {
        position: "fixed",
        transition: "top 0.3s",
        width: "100%",
    };
    const cellStyle: CSSProperties = {
        minWidth: `calc(100vw / ${5 + (positioningDisplay?.organisms?.length ?? 0)})`,
        maxWidth: `calc(100vw / ${5 + (positioningDisplay?.organisms?.length ?? 0)})`,
        width: `calc(100vw / ${5 + (positioningDisplay?.organisms?.length ?? 0)})`,
    };

    useEffect(() => {
        if (dockBlockId) {
            setDockBlock(dockBlockId);
        }

        const fetchData = async () => {
            try {
                const result = await fetchManagementPositioningDisplayAction(organization, dockBlock);
                setPositioningDisplay(result);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

        const intervalId = setInterval(fetchData, Milliseconds.TWENTY_SEC);
        return () => clearInterval(intervalId);
    }, [organization, dockBlock]);

    const onSelectorChange = (option: any, name: string) => {
        setDockBlock(option.key);
    };

    const header = () => {
        let content: JSX.Element[] = [];
        content.push(<div style={cellStyle}>{t("DOCK")}</div>);
        content.push(<div style={cellStyle}>{t("TRANSPORT_UNIT")}</div>);
        content.push(
            <div
                className={style.center}
                style={cellStyle}
            >
                {t("HOUR_DOCK")}
            </div>,
        );
        content.push(
            <div
                className={style.center}
                style={cellStyle}
            >
                {t("READY_FOR_INSPECTION")}
            </div>,
        );
        content.push(<div style={cellStyle}>{t("DESCRIPTION")}</div>);

        return [...content, ...organisms()];
    };

    const organisms = () => {
        let content: JSX.Element[] = [];
        positioningDisplay?.organisms?.map((value) => {
            content.push(
                <div
                    className={style.center}
                    style={cellStyle}
                >
                    {value.description}
                </div>,
            );
        });
        return content;
    };

    const organismsWithElements = (item: IManagmentPositioningDocks, organism: string) => {
        let content = (
            <div
                className={style.cell}
                style={cellStyle}
            ></div>
        );
        item.positioningOrganisms.find((positioning) => {
            if (organism === positioning.code) {
                content = (
                    <div
                        className={`${style.cell}`}
                        style={cellStyle}
                    >
                        <div
                            className={`${!positioning.inspectorArrivedOn ? style.physicalExaminationCell : style.inspectorReviewCell}`}
                            style={{ fontSize: positioning.certificates[0].length > 20 && !positioning.inspectorArrivedOn ? "0.7vw" : "" }}
                        >
                            {positioning.inspectorArrivedOn
                                ? convertToLocal(positioning.inspectorArrivedOn, FormatDate.HOUR_MINUTE)
                                : positioning.certificates[0]}
                        </div>
                    </div>
                );
            }
        });

        return content;
    };

    const rows = () => {
        let content: JSX.Element[] = [];
        positioningDisplay?.positioningDocks?.forEach((value, index) => {
            content.push(
                <div
                    className={`${style.row} 
                    ${value.positioningOrganisms.length > 0 && !value.allInspectorsArrived ? style.physicalExaminationRow : ""}
                    ${value.positioningOrganisms.length > 0 && value.allInspectorsArrived ? style.inspectorReviewRow : ""}`}
                >
                    <div
                        className={`${style.requestDetailRow} ${style.cell}`}
                        style={cellStyle}
                    >
                        {value.dockDescription}
                    </div>
                    <div
                        className={`${style.requestDetailRow} ${style.cell}`}
                        style={cellStyle}
                    >
                        {value.transportUnitNumber ?? t("EMPTY")}
                    </div>
                    <div
                        className={`${style.requestDetailRow} ${style.cell} ${style.center}`}
                        style={cellStyle}
                    >
                        {value.dockDate ? convertToLocal(value.dockDate, FormatDate.HOUR_MINUTE) : ""}
                    </div>
                    <div
                        className={`${style.requestDetailRow} ${style.cell} ${style.center}`}
                        style={cellStyle}
                    >
                        {value.readyForInspection && (
                            <CustomIcons
                                iconName={"SkypeCircleCheck"}
                                margin={"0px"}
                                className={style.icon}
                            />
                        )}
                    </div>
                    <div
                        className={`${style.requestDetailRow} ${style.cell}`}
                        style={cellStyle}
                    >
                        {value.requestDescription}
                    </div>
                    {positioningDisplay?.organisms?.map((organism) => {
                        return organismsWithElements(value, organism.code);
                    })}
                </div>,
            );
        });

        return content;
    };

    const helperUser = () => {
        return (
            <>
                <div className={style.helperContent}>
                    <div className={style.helperItem}>
                        <div>
                            <div className={`${style.helperCell}  ${style.physicalExaminationCellNoPaddings}`}></div>
                        </div>
                        <div>{t("HELPER.PHYSICAL_EXAMINATION")}</div>
                    </div>
                    <div className={style.helperItem}>
                        <div>
                            <div className={`${style.helperCell}  ${style.inspectorReviewCell}`}></div>
                        </div>

                        <div>{t("HELPER.INSPECTOR_REVIEW")}</div>
                    </div>
                    <div className={style.helperItem}>
                        <div>
                            <div className={style.iconHelper}>
                                <CustomIcons
                                    iconName={"SkypeCircleCheck"}
                                    margin={"0px"}
                                    className={`${style.icon}`}
                                />
                            </div>
                        </div>
                        <div>{t("HELPER.READY_FOR_INSPECTION")}</div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div style={headerStyle}>
                <NavHeaderComponent
                    title={t("TITLE")}
                    endAction={
                        <>
                            <div>
                                <CustomIcons
                                    id={helperId}
                                    iconName={"Info"}
                                    onClick={toggleHelperVisible}
                                    className={style.infoIcon}
                                    position={"start"}
                                    margin={"0px"}
                                    size={25}
                                />
                                {helperVisible && (
                                    <TeachingBubbleComponent
                                        id={helperId}
                                        onToggle={toggleHelperVisible}
                                        children={helperUser()}
                                    />
                                )}
                            </div>
                            {!organization && (
                                <div className={style.dockBlockSelector}>
                                    <DockBlockSelector
                                        onChange={onSelectorChange}
                                        selectedKey={dockBlock}
                                        propertyName={"dockBlockId"}
                                        title={""}
                                        control={control}
                                        withCode={true}
                                        allOption={true}
                                    />
                                </div>
                            )}
                            <EnvironmentDescription /> <DigitalTimer className={style.timer} />
                        </>
                    }
                    environmentBackground={true}
                />
                <div className={style.header}>{header()}</div>
            </div>
            <div className={style.gridContent}>{rows()}</div>
        </>
    );
};
