import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppSelector } from "../../../../../redux/hooks";
import { ManagementUserDefaultFormStyle } from "../baseFormStyle.jss";
import { IPivotItemProps, Icon, Pivot, PivotItem } from "@fluentui/react";
import { ManagementUserDefaultFormData } from "./user/user";
import { ManagementUserFormOrganizationsTab } from "../common/organizationsTab/organizationsTab";
import { ManagementUserParentSelector } from "../common/parentSelector/parentSelector";
import { RoleType } from "../../../../../common/enum";
import { IRole } from "../../../../../models/common/IRole";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole, roleSelector } from "../../../../../redux/selectors/user/role/role";
import { setManagementUserSelectedPivotKey } from "../../../../../redux/reducers/management/user/form";

interface IManagementUserDefaultFormProps {
    editMode: boolean;
    onSelectorChange: (event: any, option: any, name: string) => any;
    onInputChange: (event: any) => any;
    setIsAvailableSave: (value: any) => any;
    onCheckBoxChange: (ev?: any, checked?: boolean) => void;
}

export const ManagementUserDefaultForm: React.FC<IManagementUserDefaultFormProps> = ({
    onSelectorChange,
    onInputChange,
    editMode,
    setIsAvailableSave,
    onCheckBoxChange,
}) => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const dispatch = useDispatch();
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const { selectedPivotKey, generalUserData } = useAppSelector((store) => store.managementUserForm);
    const activeUserRole: IRole = useSelector(getUserRole);
    const [validUserDataTab, setValidUserDataTab] = useState<boolean>(false);

    const setSelectedPivotKey = (key: string) => {
        dispatch(setManagementUserSelectedPivotKey(key));
    };

    const bubbleRender = (link?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null) => {
        if (!link || !defaultRenderer) {
            return null;
        }

        let showBubble: boolean = false;
        switch (link.itemKey) {
            case "0":
                showBubble = !validUserDataTab;
                break;
        }

        return (
            <span style={{ flex: "0 1 100%" }}>
                {defaultRenderer({ ...link, itemIcon: undefined })}
                {showBubble && (
                    <Icon
                        iconName={link.itemIcon}
                        style={{ color: "orange" }}
                    />
                )}
            </span>
        );
    };

    useEffect(() => {
        setIsAvailableSave(validUserDataTab);
    }, [validUserDataTab]);


    return !editMode &&
        !generalUserData.parentId &&
        [RoleType.SUPER_ADMIN, RoleType.AREA_DIRECTOR, RoleType.DIRECTOR].includes(activeUserRole.id as RoleType) &&
        (generalUserData.roleId === RoleType.CUSTOMER_USER ||
            generalUserData.roleId === RoleType.CARRIER_USER ||
            generalUserData.roleId === RoleType.AGENT_USER ||
            generalUserData.roleId === RoleType.ORGANISM_TECHNICIAN ||
            generalUserData.roleId === RoleType.ORGANISM_INSPECTOR ||
            generalUserData.roleId === RoleType.PORT_LECTOR_USER ||
            generalUserData.roleId === RoleType.TERMINAL_USER) ? (
        <ManagementUserParentSelector
            generalUserData={generalUserData}
            onSelectorChange={onSelectorChange}
            setIsValidTab={setValidUserDataTab}
            editMode={editMode}
        />
    ) : generalUserData.availableOrganizations.length > 1 ? (
        <Pivot
            selectedKey={String(selectedPivotKey)}
            className={style.pivot}
            onLinkClick={(event) => {
                setSelectedPivotKey(event?.props.itemKey ? event?.props.itemKey : "0");
            }}
        >
            <PivotItem
                headerText={t("TABS.USER")}
                itemKey="0"
                itemIcon="LocationDot"
                onRenderItemLink={bubbleRender}
                alwaysRender={true}
            >
                <div className={style.pivotItem}>
                    <ManagementUserDefaultFormData
                        generalUserData={generalUserData}
                        setIsValidTab={setValidUserDataTab}
                        onInputChange={onInputChange}
                        editMode={editMode}
                        onCheckBoxChange={onCheckBoxChange}
                    />
                </div>
            </PivotItem>
            <PivotItem
                headerText={t("TABS.ORGANIZATIONS")}
                itemKey="1"
                alwaysRender={true}
            >
                <div className={style.pivotItem}>
                    <ManagementUserFormOrganizationsTab generalUserData={generalUserData} />
                </div>
            </PivotItem>
        </Pivot>
    ) : (
        <ManagementUserDefaultFormData
            generalUserData={generalUserData}
            setIsValidTab={setValidUserDataTab}
            onInputChange={onInputChange}
            onCheckBoxChange={onCheckBoxChange}
            editMode={editMode}
        />
    );
};
