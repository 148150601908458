import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { mediaQueries } from "../../../common/constants";
import { fetchAppointmentAPBABorderInspectionsummariesListAction, fetchAppointmentAssignmentQueuesListAction } from "../../../redux/actions/appointments/integrationsHistoric/historic";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { AppointmentHistoryController } from "./controller/controller";
import { AppointmentIntegrationHistoricFilter } from "./filter/filter";
import { AppointmentIntegrationsHistoricList } from "./list/list";

export enum AppointmentHistoric {
    APBA = "1",
    ASSIGNMENT_QUEUE = "2",
}

export const AppointmentIntegrationsHistoricContainer = () => {
    const { t } = useTranslation("common");
    const theme: any = useTheme();
    const [selectedKey, setSelectedKey] = useState<string>(AppointmentHistoric.ASSIGNMENT_QUEUE);
    const dispatch = useAppDispatch();

    const apbaBorderInspectionSummaries = useAppSelector((state) => state.apbaBorderInspectionSummaries);
    const assignmentQueue = useAppSelector((state) => state.assignmentQueue);

    const pivotStyles: Partial<IPivotStyles> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 208,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
        };
    }, [theme.colors, theme.fonts]);

    useEffect(() => {
        selectedKey === AppointmentHistoric.APBA && dispatch(fetchAppointmentAPBABorderInspectionsummariesListAction());
        selectedKey === AppointmentHistoric.ASSIGNMENT_QUEUE && dispatch(fetchAppointmentAssignmentQueuesListAction());
    }, [selectedKey]);

    return (
        <>
            <NavHeaderComponent title={t("APPOINTMENT_HISTORIC.TITLE")} />
            <AppointmentIntegrationHistoricFilter selectedPivot={selectedKey} />
            <Pivot
                styles={pivotStyles}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                }}
            >
                <PivotItem
                    headerText={t("APPOINTMENT_HISTORIC.ASSIGNMENT_QUEUE")}
                    itemKey={AppointmentHistoric.ASSIGNMENT_QUEUE}
                >
                    {
                        <AppointmentIntegrationsHistoricList
                            list={assignmentQueue.list}
                            loading={assignmentQueue.loading}
                            selectedKey={AppointmentHistoric.ASSIGNMENT_QUEUE}
                        />
                    }
                </PivotItem>   
                <PivotItem
                    headerText={t("APPOINTMENT_HISTORIC.APBA")}
                    itemKey={AppointmentHistoric.APBA}
                >
                    {
                        <AppointmentIntegrationsHistoricList
                            list={apbaBorderInspectionSummaries.list}
                            loading={apbaBorderInspectionSummaries.loading}
                            selectedKey={AppointmentHistoric.APBA}
                        />
                    }
                </PivotItem>           
            </Pivot>
            <AppointmentHistoryController />
        </>
    );
};
