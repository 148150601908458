import { createUseStyles } from "react-jss";

export const NavHeaderStyled = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        gap: theme.gaps.sizes.xl,
        width: "100%",
        height: theme.heights.siteHeaderNav,
        padding: "0 15px 0 10px",
        backgroundColor:props.background ?? theme.colors.headerNav.background,
        color: theme.colors.headerNav.color,
    }),
    titleContainer: {
        display: "flex",
        fontSize: theme.fonts.sizes.lg,
        justifyItems: "flex-start",
        alignItems: "center",
    },
    title: {
        paddingLeft: "15px",
    },
    action: (props: any) => ({
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.gaps.sizes.md,
        " & .ms-Button-flexContainer": {
            alignItems: "flex-start",
            marginTop: "6px",
        },
        paddingLeft: !props.showTitleContainer ? 15 : 0,

        flex: "1 1 auto",
    }),
    endAction: {
        height: "100%",
        marginLeft: "auto",
        display: "flex",
        gap: theme.gaps.sizes.sm,
        justifyContent: "flex-end",
        alignItems: "center",
        " & .ms-Button-flexContainer": {
            alignItems: "flex-start",
            marginTop: "6px",
        },
        flex: "1 1 auto",
    },
}));
