import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import appointmentBoardGridReducer from "./reducers/appointment/board";
import appointmentHistoricFilterReducer from "./reducers/appointment/historic/filter";
import appointmetnHistoricFormReducer from "./reducers/appointment/historic/form";
import appointmentHistoricAppointmentReducer from "./reducers/appointment/historic/list/appointment";
import appointmentHistoricIncidenceReducer from "./reducers/appointment/historic/list/incidence";
import appointmentHistoricInspectorReducer from "./reducers/appointment/historic/list/inspector";
import apbaBorderInspectionSummariesListReducer from "./reducers/appointment/historic/list/apbaBorderInspectionSummaries";
import assignmentQueueListReducer from "./reducers/appointment/historic/list/assignmentQueue";
import appointmentAvailableZoneReducer from "./reducers/appointment/zoneAvailable";
import requestPendingAssignmentFilterReducer from "./reducers/assignment/filter";
import requestPendingAssignmentListReducer from "./reducers/assignment/list";
import catalogReducer from "./reducers/catalog/catalog";
import errorHandlerReducer from "./reducers/common/errorHandler";
import globalLoadingReducer from "./reducers/common/globalLoading";
import initSessionReducer from "./reducers/common/initSessionKeys";
import configDockBlockFilterReducer from "./reducers/config/dockBlock/filters";
import configDockBlockFormReducer from "./reducers/config/dockBlock/form";
import configDockBlockListReducer from "./reducers/config/dockBlock/list";
import configDockMerchandiseFilterReducer from "./reducers/config/dockMerchandise/filter";
import configDockMerchandiseFormReducer from "./reducers/config/dockMerchandise/form";
import configDockMerchandiseListReducer from "./reducers/config/dockMerchandise/list";
import configMerchandiseCategoryFilterReducer from "./reducers/config/merchandiseCategory/filter";
import configMerchandiseCategoryFormReducer from "./reducers/config/merchandiseCategory/form";
import configMerchandiseCategoryListReducer from "./reducers/config/merchandiseCategory/list";
import controlExpedientsFilterReducer from "./reducers/control/expedients/filter";
import controlExpedientsFormReducer from "./reducers/control/expedients/form";
import controlExpedientsListReducer from "./reducers/control/expedients/list";
import invoicingNonSynchronizedListReducer from "./reducers/invoicing/nonSynchronized/list";
import invoicingSummaryConceptFilterReducer from "./reducers/invoicing/summaryByConcept/filter";
import invoicingSummaryConceptListReducer from "./reducers/invoicing/summaryByConcept/list";
import invoicingUninvoicedFilterReducer from "./reducers/invoicing/uninvoiced/filter";
import invoicingUninvoicedListReducer from "./reducers/invoicing/uninvoiced/list";
import managementAuthorizedTransportUnitNumberFilterReducer from "./reducers/management/authorizedTransportUnitNumber/filter";
import managementAuthorizedTransportUnitNumberFormReducer from "./reducers/management/authorizedTransportUnitNumber/form";
import managementAuthorizedTransportUnitNumberListReducer from "./reducers/management/authorizedTransportUnitNumber/list";
import managementDockDisplayReducer from "./reducers/management/dockDisplay/dockDisplay";
import managementIncidenceTypesFilterReducer from "./reducers/management/incidenceType/filter";
import managementIncidenceTypesFormReducer from "./reducers/management/incidenceType/form";
import managementIncidenceTypesListReducer from "./reducers/management/incidenceType/list";
import managementMenuSettingsByRoleFormSlice from "./reducers/management/menuSettingsByRole/form";
import managementNotificationFilterReducer from "./reducers/management/notification/filter";
import managementNotificationFormReducer from "./reducers/management/notification/form";
import managementNotificationListReducer from "./reducers/management/notification/list";
import managementParkingValidationRuleFormSlice from "./reducers/management/parkingValidation/form";
import managementParkingValidationRuleListSlice from "./reducers/management/parkingValidation/list";
import managementParkingValidationConfigurationSlice from "./reducers/management/parkingValidation/view";
import managementPositioningDisplayReducer from "./reducers/management/positioningDisplay/positioningDisplay";
import managementRepresentationFilterReducer from "./reducers/management/representation/filter";
import managementRepresentationFormReducer from "./reducers/management/representation/form";
import managementRepresentationListReducer from "./reducers/management/representation/list";
import managementRequestPermissionFormReducer from "./reducers/management/requestdatepermission/form";
import managementRequestDatePermissionListReducer from "./reducers/management/requestdatepermission/list";
import managementSummaryFilterReducer from "./reducers/management/summary/filter";
import managementSummaryFormReducer from "./reducers/management/summary/form";
import managementSummaryListReducer from "./reducers/management/summary/list";
import managementTariffFormReducer from "./reducers/management/tariff/form";
import managementTariffListReducer from "./reducers/management/tariff/list";
import managementUserFilterReducer from "./reducers/management/user/filter";
import managementUserFormReducer from "./reducers/management/user/form";
import managementUserListReducer from "./reducers/management/user/list";
import managementUserSignupFilterReducer from "./reducers/management/userSignup/filter";
import managementUserSignupListReducer from "./reducers/management/userSignup/list";
import managementWorkCalendarListReducer from "./reducers/management/workCalendar/list";
import masterAppointmentDockFormReducer from "./reducers/masterAppointment/appointmentBoard/appointmentDock/form";
import masterAppointmentTimeZoneReducer from "./reducers/masterAppointment/appointmentBoard/appointmentDockTimeZone/form";
import masterAppointmentTimeZoneConfigurationReducer from "./reducers/masterAppointment/appointmentBoard/appointmentDockTimeZoneConfiguration/form";
import masterAppointmentTypeConfigurationFormReducer from "./reducers/masterAppointment/appointmentBoard/appointmentTypeConfiguration/form";
import masterAppointmentBoardEditReducer from "./reducers/masterAppointment/appointmentBoard/edit";
import masterAppointmentBoardCreateFormReducer from "./reducers/masterAppointment/appointmentBoard/form";
import masterAppointmentBoardListReducer from "./reducers/masterAppointment/appointmentBoard/list";
import masterAppointmentDatePermissionFormReducer from "./reducers/masterAppointment/appointmentDatePermission/form";
import masterAppointmentDatePermissionListReducer from "./reducers/masterAppointment/appointmentDatePermission/list";
import masterAppointmentPermissionFormSlice from "./reducers/masterAppointment/appointmentPermission/form";
import masterAppointmentPermissionListSlice from "./reducers/masterAppointment/appointmentPermission/list";
import masterAppointmentTypesFormReducer from "./reducers/masterAppointment/appointmentType/form";
import masterAppointmentTypesListReducer from "./reducers/masterAppointment/appointmentType/list";
import masterAppointmentTypeActuationFormReducer from "./reducers/masterAppointment/appointmentTypeActuation/form";
import masterAppointmentTypeActuationListReducer from "./reducers/masterAppointment/appointmentTypeActuation/list";
import masterAppointmentReducer from "./reducers/masterAppointment/masterAppointment";
import createRequestReducer from "./reducers/request/create/createRequest";
import DraftReducer from "./reducers/request/draft/draft";
import draftlist from "./reducers/request/draft/draftlist";
import editRequestReducer from "./reducers/request/edit/editRequest";
import actuationFormReducer from "./reducers/request/edit/form/actuation";
import assignmentQueueImportFormReducer from "./reducers/request/assignmentQueue/form";
import assignmentQueueImportListReducer from "./reducers/request/assignmentQueue/list";
import editRequestAppointmentReducer from "./reducers/request/edit/form/appointment";
import editRequestInvoiceConceptFormReducer from "./reducers/request/edit/form/concept";
import editRequestIncidenceReducer from "./reducers/request/edit/form/incidence";
import editRequestInspectionFormReducer from "./reducers/request/edit/form/inspection";
import editRequestHistoryChangeFormReducer from "./reducers/request/edit/history/form";
import editRequestHistoryListReducer from "./reducers/request/edit/history/list";
import requestExportListReducer from "./reducers/request/list/export";
import requestListFilterReducer from "./reducers/request/list/filter";
import assignInspectorReducer from "./reducers/request/list/form/assignInspector";
import addInspectionReducer from "./reducers/request/list/form/addInspection";
import requestlist from "./reducers/request/list/requestlist";
import requestIntegrationFilterReducer from "./reducers/request/requestIntegrations/filter";
import requestIntegrationListReducer from "./reducers/request/requestIntegrations/list/integrationList";
import requestIntegrationFormReducer from "./reducers/request/requestIntegrations/form";
import carrierImportReducer from "./reducers/resources/carrierImport/import";
import customerImportReducer from "./reducers/resources/customersImport/import";
import masterActuationGroupFormReducer from "./reducers/resources/master/actuationGroup/form";
import masterActuationGroupListReducer from "./reducers/resources/master/actuationGroup/list";
import masterActuationTypeFormReducer from "./reducers/resources/master/actuationType/form";
import masterActuationTypeListReducer from "./reducers/resources/master/actuationType/list";
import masterBlockFormReducer from "./reducers/resources/master/block/form";
import masterBlockListReducer from "./reducers/resources/master/block/list";
import masterCertificateTypeFormReducer from "./reducers/resources/master/certificateType/form";
import masterCertificateTypeListReducer from "./reducers/resources/master/certificateType/list";
import masterCustomsStatesFormReducer from "./reducers/resources/master/customsStates/form";
import masterCustomsStatesListReducer from "./reducers/resources/master/customsStates/list";
import masterDocksFormReducer from "./reducers/resources/master/docks/form";
import masterDocksListReducer from "./reducers/resources/master/docks/list";
import masterDocumentTypesFormReducer from "./reducers/resources/master/documentTypes/form";
import masterDocumentTypesListReducer from "./reducers/resources/master/documentTypes/list";
import masterFinalDestinationTypesFormReducer from "./reducers/resources/master/finalDestinationTypes/form";
import masterFinalDestinationTypesListReducer from "./reducers/resources/master/finalDestinationTypes/list";
import masterGroupMerchandiseCategoryFormReducer from "./reducers/resources/master/groupMerchandiseCategory/form";
import masterGroupMerchandiseCategoryListReducer from "./reducers/resources/master/groupMerchandiseCategory/list";
import masterPopUpReducer from "./reducers/resources/master/master";
import masterMerchandiseCategoryFormReducer from "./reducers/resources/master/merchandiseCategory/form";
import masterMerchandiseCategoryListReducer from "./reducers/resources/master/merchandiseCategory/list";
import masterMerchandiseOriginsFormReducer from "./reducers/resources/master/merchandiseOrigins/form";
import masterMerchandiseOriginsListReducer from "./reducers/resources/master/merchandiseOrigins/list";
import masterMerchandiseTypesFormReducer from "./reducers/resources/master/merchandiseTypes/form";
import masterMerchandiseTypesListReducer from "./reducers/resources/master/merchandiseTypes/list";
import masterNotificationTypesFormReducer from "./reducers/resources/master/notificationType/form";
import masterNotificationTypesListReducer from "./reducers/resources/master/notificationType/list";
import masterOrganismFormReducer from "./reducers/resources/master/organism/form";
import masterOrganismListReducer from "./reducers/resources/master/organism/list";
import masterPresenceRepresentativeFormReducer from "./reducers/resources/master/representativePresence/form";
import masterPresenceRepresentativeListReducer from "./reducers/resources/master/representativePresence/list";
import masterRequestStatesFormReducer from "./reducers/resources/master/requestStates/form";
import masterRequestStatesListReducer from "./reducers/resources/master/requestStates/list";
import masterSealTypesFormReducer from "./reducers/resources/master/sealTypes/form";
import masterSealTypesListReducer from "./reducers/resources/master/sealTypes/list";
import masterTerminalFormReducer from "./reducers/resources/master/terminal/form";
import masterTerminalListReducer from "./reducers/resources/master/terminal/list";
import masterTransportUnitFormReducer from "./reducers/resources/master/transportUnit/form";
import masterTransportUnitListReducer from "./reducers/resources/master/transportUnit/list";
import masterTurnsFormReducer from "./reducers/resources/master/turns/form";
import masterTurnsListReducer from "./reducers/resources/master/turns/list";
import configOrganismCertificateTypeFilterReducer from "./reducers/resources/organismCertificatedType/filter";
import configOrganismCertificateTypeFormReducer from "./reducers/resources/organismCertificatedType/form";
import configOrganismCertificateTypeListReducer from "./reducers/resources/organismCertificatedType/list";
import statiticsCustomerFilterReducer from "./reducers/statistics/customer/filter";
import statiticsCustomerListReducer from "./reducers/statistics/customer/list";
import statisticsDailyChargeFilterReducer from "./reducers/statistics/dailyCharge/filter";
import statiticsDailyChargeListReducer from "./reducers/statistics/dailyCharge/list";
import statisticsOperatorIncidenceFilterReducer from "./reducers/statistics/incidenceOperator/filter";
import statisticsOperatorIncidenceListReducer from "./reducers/statistics/incidenceOperator/list";
import statisticsOrganismIncidenceFilterReducer from "./reducers/statistics/incidenceOrganism/filter";
import statisticsOrganismIncidenceListReducer from "./reducers/statistics/incidenceOrganism/list";
import statisticsInspectionAccumulativeListReducer from "./reducers/statistics/inspection/accumulative";
import statisticsInspectionDetailedListReducer from "./reducers/statistics/inspection/detailed";
import statisticsInspectionFilterReducer from "./reducers/statistics/inspection/filter";
import statisticsInspectionGroupedListReducer from "./reducers/statistics/inspection/grouped";
import statisticsInspectionListReducer from "./reducers/statistics/inspection/list";
import statisticsMonthlySummaryFilterReducer from "./reducers/statistics/monthlySummary/filter";
import statisticsMonthlySummaryListReducer from "./reducers/statistics/monthlySummary/list";
import statisticsReaderRecordRatioFilterReducer from "./reducers/statistics/readerRecordRatio/filter";
import statiticsReaderRecordRatioListReducer from "./reducers/statistics/readerRecordRatio/list";
import statiticsRequestGraphReducer from "./reducers/statistics/requestGraph/list";
import requestUpcomingAppointmentsFilterReducer from "./reducers/upcomingAppointments/filter";
import requestUpcomingAppointmentsListReducer from "./reducers/upcomingAppointments/list";
import upcomingAppointmentsSortFilterReducer from "./reducers/upcomingAppointmentsSort/filter";
import requestUpcomingAppointmentsSortListReducer from "./reducers/upcomingAppointmentsSort/list";
import userClaimsReducer from "./reducers/user/claims/claims";
import userSignupConfirmationReducer from "./reducers/user/confirmation/confirmation";
import modulesReducer from "./reducers/user/module/module";
import organizationReducer from "./reducers/user/organization/organization";
import registeredEmailsReducer from "./reducers/user/profile/registerUsers";
import userProfile from "./reducers/user/profile/userProfile";
import representativeReducer from "./reducers/user/representatives/userRepresentatives";
import roleReducer from "./reducers/user/role/role";
import userRoleViewReducer from "./reducers/user/role/view";
import userSignupDocumentTemplatesReducer from "./reducers/user/signup/documentTemplates";
import selfSignupReducer from "./reducers/user/signup/selfSignup";
import userSignupReducer from "./reducers/user/signup/signup";
import userSignupStatesReducer from "./reducers/user/signup/states";
import userSignupStepperReducer from "./reducers/user/signup/stepper";
import userSignupTypesReducer from "./reducers/user/signup/types";
import usersbyRoleReducer from "./reducers/user/users/usersByRole";
import userDataReducer from "./reducers/userData/userData";
import vlcportHistoricFilterReducer from "./reducers/vlcPort/historic/filter";
import vlcportHistoricFormReducer from "./reducers/vlcPort/historic/form";
import vlcportHistoricInspectionAppoimentListReducer from "./reducers/vlcPort/historic/list/inspectionAppoiment";
import vlcportHistoricInspectionResultListReducer from "./reducers/vlcPort/historic/list/inspectionResult";
import vlcportHistoricPositioningRequestListReducer from "./reducers/vlcPort/historic/list/positioningRequest";
import VlcportImportRequestFormReducer from "./reducers/vlcPort/importRequest/form";
import VlcPortImportRequestListReducer from "./reducers/vlcPort/importRequest/list";
import VlcPortRequestFinishedListReducer from "./reducers/vlcPort/requestFinished/list";
import apiExceptionReducer from "./reducers/webConfig/apiException";
import globalConfigReducer from "./reducers/webConfig/globalConfig";
import appointmentImportFormReducer from "./reducers/appointment/appoitmentImport/form";
import appointmentImportListReducer from "./reducers/appointment/appoitmentImport/list";
import managementDockQueueRuleListReducer from "./reducers/management/dockQueueRule/list";
import managementDockQueueRuleConfigurationReducer from "./reducers/management/dockQueueRule/view";
import managementDockQueueRuleFormReducer from "./reducers/management/dockQueueRule/form";

export const store = configureStore({
    reducer: {
        userData: userDataReducer,
        userClaims: userClaimsReducer,
        organization: organizationReducer,
        role: roleReducer,
        modules: modulesReducer,
        userSignup: userSignupReducer,
        userSignupStepper: userSignupStepperReducer,
        userSignupTypes: userSignupTypesReducer,
        userSignupStates: userSignupStatesReducer,
        userSignupDocumentTemplates: userSignupDocumentTemplatesReducer,
        userSignupConfirmation: userSignupConfirmationReducer,
        userRoleViews: userRoleViewReducer,
        userRepresentative: representativeReducer,
        globalLoading: globalLoadingReducer,
        errorHandler: errorHandlerReducer,
        userProfile: userProfile,
        requestlist: requestlist,
        registeredEmails: registeredEmailsReducer,
        selfSignUp: selfSignupReducer,
        catalog: catalogReducer,
        createRequest: createRequestReducer,
        draftRequest: DraftReducer,
        editRequest: editRequestReducer,
        usersByRole: usersbyRoleReducer,
        requestListFilter: requestListFilterReducer,
        initSession: initSessionReducer,
        requestExportList: requestExportListReducer,
        draftlist: draftlist,
        invoicingSummaryConceptList: invoicingSummaryConceptListReducer,
        invoicingSummaryConceptFilter: invoicingSummaryConceptFilterReducer,
        invoicingUninvoicedConceptList: invoicingUninvoicedListReducer,
        invoicingUninvoicedConceptFilter: invoicingUninvoicedFilterReducer,
        invoicingNonSynchronizedList: invoicingNonSynchronizedListReducer,
        managementUserList: managementUserListReducer,
        managementUserFilter: managementUserFilterReducer,
        managementUserForm: managementUserFormReducer,
        managementUserSignupList: managementUserSignupListReducer,
        managementUserSignupFilter: managementUserSignupFilterReducer,
        managementRepresentationList: managementRepresentationListReducer,
        managementRepresentationFilter: managementRepresentationFilterReducer,
        managementRepresentationForm: managementRepresentationFormReducer,
        managementNotificationList: managementNotificationListReducer,
        managementNotificationFilter: managementNotificationFilterReducer,
        managementNotificationForm: managementNotificationFormReducer,
        managementTariffForm: managementTariffFormReducer,
        managementTariffList: managementTariffListReducer,
        managementWorkCalendarList: managementWorkCalendarListReducer,
        statiticsCustomerFilter: statiticsCustomerFilterReducer,
        statiticsCustomerList: statiticsCustomerListReducer,
        actuationForm: actuationFormReducer,
        assignInspectorForm: assignInspectorReducer,
        addInspectionForm: addInspectionReducer,
        editRequestIncidenceForm: editRequestIncidenceReducer,
        statisticsOrganismIncidenceFilter: statisticsOrganismIncidenceFilterReducer,
        statisticsOrganismIncidenceList: statisticsOrganismIncidenceListReducer,
        statisticsOperatorIncidenceList: statisticsOperatorIncidenceListReducer,
        statisticsOperatorIncidenceFilter: statisticsOperatorIncidenceFilterReducer,
        statisticsInspectionList: statisticsInspectionListReducer,
        statisticsInspectionFilter: statisticsInspectionFilterReducer,
        statisticsMonthlySummaryList: statisticsMonthlySummaryListReducer,
        statisticsMonthlySummaryFilter: statisticsMonthlySummaryFilterReducer,
        statisticsDailyChargeFilter: statisticsDailyChargeFilterReducer,
        statiticsDailyChargeList: statiticsDailyChargeListReducer,
        statiticsReaderRecordRatioList: statiticsReaderRecordRatioListReducer,
        statisticsReaderRecordRatioFilter: statisticsReaderRecordRatioFilterReducer,
        statiticsInspectionAccumulativeList: statisticsInspectionAccumulativeListReducer,
        statiticsInspectionDetailedList: statisticsInspectionDetailedListReducer,
        statiticsInspectionGroupedList: statisticsInspectionGroupedListReducer,
        statiticsRequestGraph: statiticsRequestGraphReducer,
        managementDockDisplay: managementDockDisplayReducer,
        managementPositioningDisplay: managementPositioningDisplayReducer,
        managementIncidenceTypesList: managementIncidenceTypesListReducer,
        managementIncidenceTypesForm: managementIncidenceTypesFormReducer,
        managementIncidenceTypesFilter: managementIncidenceTypesFilterReducer,
        managementRequestDatePermisionList: managementRequestDatePermissionListReducer,
        managementRequestDatePermissionForm: managementRequestPermissionFormReducer,
        controlExpedientsList: controlExpedientsListReducer,
        controlExpedientsFilter: controlExpedientsFilterReducer,
        controlExpedientsForm: controlExpedientsFormReducer,
        globalConfig: globalConfigReducer,
        masterOrganismList: masterOrganismListReducer,
        masterOrganismForm: masterOrganismFormReducer,
        masterPopUp: masterPopUpReducer,
        editRequestInvoiceConceptForm: editRequestInvoiceConceptFormReducer,
        masterCertificateTypeList: masterCertificateTypeListReducer,
        masterCertificateTypeForm: masterCertificateTypeFormReducer,
        masterMerchandiseOriginsList: masterMerchandiseOriginsListReducer,
        masterMerchandiseOriginsForm: masterMerchandiseOriginsFormReducer,
        masterMerchandiseTypesList: masterMerchandiseTypesListReducer,
        masterMerchandiseTypesForm: masterMerchandiseTypesFormReducer,
        masterMerchandiseCategoryList: masterMerchandiseCategoryListReducer,
        masterMerchandiseCategoryForm: masterMerchandiseCategoryFormReducer,
        masterDocksList: masterDocksListReducer,
        masterDocksForm: masterDocksFormReducer,
        masterDocumentTypesForm: masterDocumentTypesFormReducer,
        masterDocumentTypesList: masterDocumentTypesListReducer,
        masterSealTypesList: masterSealTypesListReducer,
        masterSealTypesForm: masterSealTypesFormReducer,
        masterFinalDestinationTypesList: masterFinalDestinationTypesListReducer,
        masterFinalDestinationTypesForm: masterFinalDestinationTypesFormReducer,
        masterPresenceRepresentativeList: masterPresenceRepresentativeListReducer,
        masterPresenceRepresentativeForm: masterPresenceRepresentativeFormReducer,
        masterTurnsForm: masterTurnsFormReducer,
        masterTurnsList: masterTurnsListReducer,
        masterRequestStatesList: masterRequestStatesListReducer,
        masterRequestStatesForm: masterRequestStatesFormReducer,
        requestPendingAssignmentList: requestPendingAssignmentListReducer,
        requestPendingAssignmentFilter: requestPendingAssignmentFilterReducer,
        managementSummaryList: managementSummaryListReducer,
        managementSummaryForm: managementSummaryFormReducer,
        managementSummaryFilter: managementSummaryFilterReducer,
        requestUpcomingAppointmentsList: requestUpcomingAppointmentsListReducer,
        requestUpcomingAppointmentsFilter: requestUpcomingAppointmentsFilterReducer,
        masterBlockList: masterBlockListReducer,
        masterBlockForm: masterBlockFormReducer,
        configMerchandiseCategoryList: configMerchandiseCategoryListReducer,
        configMerchandiseCategoryForm: configMerchandiseCategoryFormReducer,
        configMerchandiseCategoryFilter: configMerchandiseCategoryFilterReducer,
        configDockMerchandiseList: configDockMerchandiseListReducer,
        configDockMerchandiseForm: configDockMerchandiseFormReducer,
        configDockMerchandiseFilter: configDockMerchandiseFilterReducer,
        editRequestInspectionForm: editRequestInspectionFormReducer,
        configDockBlockForm: configDockBlockFormReducer,
        configDockBlockList: configDockBlockListReducer,
        configDockBlockFilter: configDockBlockFilterReducer,
        masterActuationGroupList: masterActuationGroupListReducer,
        masterActuationGroupForm: masterActuationGroupFormReducer,
        masterActuationTypeList: masterActuationTypeListReducer,
        masterActuationTypeForm: masterActuationTypeFormReducer,
        masterAppointment: masterAppointmentReducer,
        masterAppointmentTypesList: masterAppointmentTypesListReducer,
        masterAppointmentTypesForm: masterAppointmentTypesFormReducer,
        masterGroupMerchandiseCategoryList: masterGroupMerchandiseCategoryListReducer,
        masterGroupMerchandiseCategoryForm: masterGroupMerchandiseCategoryFormReducer,
        masterCustomsStatesList: masterCustomsStatesListReducer,
        masterCustomsStatesForm: masterCustomsStatesFormReducer,
        masterAppointmentTypeActuationList: masterAppointmentTypeActuationListReducer,
        masterAppointmentTypeActuationForm: masterAppointmentTypeActuationFormReducer,
        masterAppointmentDatePermissionList: masterAppointmentDatePermissionListReducer,
        masterAppointmentDatePermissionForm: masterAppointmentDatePermissionFormReducer,
        masterNotificationTypesForm: masterNotificationTypesFormReducer,
        masterNotificationTypesList: masterNotificationTypesListReducer,
        masterAppointmentBoardList: masterAppointmentBoardListReducer,
        masterAppointmentBoardCreateForm: masterAppointmentBoardCreateFormReducer,
        masterAppointmentDockForm: masterAppointmentDockFormReducer,
        masterAppointmentTypeConfigurationForm: masterAppointmentTypeConfigurationFormReducer,
        masterAppointmentDockTimeZoneConfigurationForm: masterAppointmentTimeZoneConfigurationReducer,
        masterAppointmentTimeZone: masterAppointmentTimeZoneReducer,
        masterAppointmentBoardEdit: masterAppointmentBoardEditReducer,
        editRequestAppointment: editRequestAppointmentReducer,
        appointmentBoardGrid: appointmentBoardGridReducer,
        appointmentAvailableZone: appointmentAvailableZoneReducer,
        managementAuthorizedTransportUnitNumberList: managementAuthorizedTransportUnitNumberListReducer,
        managementAuthorizedTransportUnitNumberFilter: managementAuthorizedTransportUnitNumberFilterReducer,
        managementAuthorizedTransportUnitNumberForm: managementAuthorizedTransportUnitNumberFormReducer,
        editRequestHistoryRequest: editRequestHistoryListReducer,
        editRequestHistoryChangeForm: editRequestHistoryChangeFormReducer,
        vlcPortFinishedRequestList: VlcPortRequestFinishedListReducer,
        VlcportImportRequestForm: VlcportImportRequestFormReducer,
        VlcPortImportRequestList: VlcPortImportRequestListReducer,
        customerImport: customerImportReducer,
        upcomingAppointmentsSortFilter: upcomingAppointmentsSortFilterReducer,
        upcomingAppointmentsSortList: requestUpcomingAppointmentsSortListReducer,
        requestIntegrationFilter: requestIntegrationFilterReducer,
        requestIntegrationList: requestIntegrationListReducer,
        requestIntegrationForm: requestIntegrationFormReducer,
        vlcportHistoricFilter: vlcportHistoricFilterReducer,
        vlcportHistoricInspectionAppoimentList: vlcportHistoricInspectionAppoimentListReducer,
        vlcportHistoricInspectionResultList: vlcportHistoricInspectionResultListReducer,
        vlcportHistoricPositioningRequestList: vlcportHistoricPositioningRequestListReducer,
        vlcportHistoricForm: vlcportHistoricFormReducer,
        masterTransportUnitList: masterTransportUnitListReducer,
        masterTransportUnitForm: masterTransportUnitFormReducer,
        managementParkingValidationRuleList: managementParkingValidationRuleListSlice,
        managementParkingValidationRuleForm: managementParkingValidationRuleFormSlice,
        masterAppointmentPermissionForm: masterAppointmentPermissionFormSlice,
        masterAppointmentPermissionList: masterAppointmentPermissionListSlice,
        carrierImport: carrierImportReducer,
        managementMenuSettingsForm: managementMenuSettingsByRoleFormSlice,
        masterTerminalList: masterTerminalListReducer,
        masterTerminalForm: masterTerminalFormReducer,
        configOrganismCertificateTypeList: configOrganismCertificateTypeListReducer,
        configOrganismCertificateTypeForm: configOrganismCertificateTypeFormReducer,
        configOrganismCertificateTypeFilter: configOrganismCertificateTypeFilterReducer,
        appointmentHistoricFilter: appointmentHistoricFilterReducer,
        appointmentHistoricInspector: appointmentHistoricInspectorReducer,
        appointmentHistoricIncidence: appointmentHistoricIncidenceReducer,
        appointmentHistoricAppointment: appointmentHistoricAppointmentReducer,
        apbaBorderInspectionSummaries: apbaBorderInspectionSummariesListReducer,
        assignmentQueue: assignmentQueueListReducer,
        appointmetnHistoricForm: appointmetnHistoricFormReducer,
        managementParkingValidationConfiguration: managementParkingValidationConfigurationSlice,
        apiException: apiExceptionReducer,
        appointmentImportForm: appointmentImportFormReducer,
        appointmentImportList: appointmentImportListReducer,
        assignmentQueueImportForm: assignmentQueueImportFormReducer,
        assignmentQueueImportList: assignmentQueueImportListReducer,
        managementDockQueueRuleList: managementDockQueueRuleListReducer,
        managementDockQueueRuleConfiguration: managementDockQueueRuleConfigurationReducer,
        managementDockQueueRuleForm: managementDockQueueRuleFormReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: process.env.NODE_ENV === "development",
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, any, Action<string>>;
