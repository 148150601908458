import { ConfigMerchandiseCategoryAPI } from "../../../api/config/merchandiseCategory";
import { DefaultValues } from "../../../common/enum/DefaultValues";
import { throwErrorToastrAsync } from "../../../utils";
import { setConfigMerchandiseCategoryForm, setConfigMerchandiseCategoryLoadingForm } from "../../reducers/config/merchandiseCategory/form";
import { setConfigMerchandiseCategoryList, setConfigMerchandiseCategoryLoadingList } from "../../reducers/config/merchandiseCategory/list";
import { AppThunk, store } from "../../store";

const configMerchandiseCategoryApi = new ConfigMerchandiseCategoryAPI();

export const fetchConfigMerchandiseCategoryListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setConfigMerchandiseCategoryLoadingList(true));
        const response = await configMerchandiseCategoryApi.getConfigMerchandiseCategoryList(mapConfigMerchandiseCategoryFilter());
        dispatch(setConfigMerchandiseCategoryList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setConfigMerchandiseCategoryLoadingList(false));
    }
};

export const fetchConfigMerchandiseCategoryAction =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setConfigMerchandiseCategoryLoadingForm(true));
            const response = await configMerchandiseCategoryApi.getConfigMerchandiseCategory(id);
            dispatch(setConfigMerchandiseCategoryForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setConfigMerchandiseCategoryLoadingForm(false));
        }
    };

export const postAndPutConfigMerchandiseCategory = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().configMerchandiseCategoryForm;
        const newform: any = {
            ...form,
            humanConsumption: !!form.humanConsumption,
        };
        try {
            dispatch(setConfigMerchandiseCategoryLoadingForm(true));
            if (form.id) {
                await configMerchandiseCategoryApi.putConfigMerchandiseCategory(newform);
            } else {
                await configMerchandiseCategoryApi.postConfigMerchandiseCategory(newform);
            }
            dispatch(fetchConfigMerchandiseCategoryListAction());

            return true;
        } catch (error) {
            dispatch(setConfigMerchandiseCategoryLoadingForm(false));
            throwErrorToastrAsync(error);
            return false;
        }
    };
};

export const removeConfigMerchandiseCategory = (): any => {
    return async (dispatch: any) => {
        const { id } = store.getState().configMerchandiseCategoryForm.form;
        try {
            dispatch(setConfigMerchandiseCategoryLoadingForm(true));
            await configMerchandiseCategoryApi.deleteConfigMerchandiseCategory(id ?? 0);
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchConfigMerchandiseCategoryListAction());
            dispatch(setConfigMerchandiseCategoryLoadingForm(false));
        }
    };
};

const mapConfigMerchandiseCategoryFilter = () => {
    const { filter } = store.getState().configMerchandiseCategoryFilter;
    return {
        humanConsumption: filter.humanConsumption !== null ? filter.humanConsumption : null,
        merchandiseTypeId: String(filter.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.merchandiseTypeId : null,
        merchandiseOriginId: String(filter.merchandiseOriginId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.merchandiseOriginId : null,
        merchandiseCategoryId: String(filter.merchandiseCategoryId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.merchandiseCategoryId : null,
    };
};
