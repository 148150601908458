import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentIntegrationsHistoric } from "../../../../../models/appointment/IAppointmentIntegrationsHistoric";

interface IAssignmentQueueListState {
    list?: IAppointmentIntegrationsHistoric[];
    loading: boolean;
}

const initialState: IAssignmentQueueListState = {
    loading: false,
};

export const assignmentQueueList = createSlice({
    name: "assignmentQueueListReduccer",
    initialState,
    reducers: {
        resetAssignmentQueueList: () => initialState,
        setAssignmentQueueList: (state, action: PayloadAction<any[]>) => {
            state.list = action.payload;
        },
        setAssignmentQueueListLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetAssignmentQueueList, setAssignmentQueueList, setAssignmentQueueListLoading } = assignmentQueueList.actions;

export default assignmentQueueList.reducer;
