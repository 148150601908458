import { createUseStyles } from "react-jss";

export const ManagementDockDisplayContainerStyle = createUseStyles((theme: any) => ({
    generalContainer: {
        display: "flex",
        height: "calc(100vh - 4.1rem)",
        width: "100%",
        overflow: "hidden",
    },
    waiting: {
        width: "50%",
        height: "100%",
        overflow: "hidden",
    },
    assigned: {
        width: "50%",
        height: "100%",
        overflow: "hidden",
    },
    header: {
        backgroundColor: theme.colors.palette.purpleLight,
        height: 41,
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
        fontSize: "14px !important",
        fontWeight: theme.fonts.weight.bold,
        width: "100%",
        justifyContent: "center",
    },
    dataGrid:{
        padding: theme.paddings.sizes.base,
        overflow: "hidden",
    },
    data: {
        overflow: "hidden",
        display: "flex",
        gap: theme.gaps.sizes.base,
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        paddingTop: theme.paddings.sizes.base,
    },
    timer: {
        fontSize: theme.fonts.sizes.xxl,
    },
    leyend: {
        justifyContent: "center",
        width: "100%",
        color: theme.colors.site.color,
        fontWeight: theme.fonts.weight.normal,
    }
}));
