import { createUseStyles } from "react-jss";

export const ExportDataStyle = createUseStyles((theme: any) => ({
    exportBtn: {
        display: "flex",
        width: theme.widths.exportBtn.container,
        color: theme.colors.exportBtn.color,
        cursor: "pointer",
        alignItems: "center",
        
    },
}));
